import { useAbortableEffect, useDataState } from '@round/utils';
import { useCallback } from 'react';
import { creatorbase } from '@round/api';

type Params = {
    clientIds?: number[];
};

type Options = Partial<{
    params: Params;
    isDisabled: boolean;
}>;

export default function useClients({ isDisabled, params }: Options = { isDisabled: false }) {
    const [state, setState] = useDataState<creatorbase.Client[] | null>({
        status: 'idle',
        data: null,
        error: null,
    });

    const fetchData = useCallback(
        async (clientId: number, requestInit?: RequestInit) => {
            try {
                setState('loading');
                const response = await creatorbase.getClient(clientId, requestInit);
                if (response.status === 200) {
                    setState('success', (prev) => ({
                        ...prev,
                        data: prev.data?.concat(response.data) ?? [response.data],
                    }));
                    return response;
                }

                const errorMessage = response.data.detail;
                setState('error', { error: errorMessage });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                setState('error', { error: 'Could not get client' });
                throw e;
            }
        },
        [setState]
    );

    useAbortableEffect(
        (signal) => {
            if (isDisabled) {
                return;
            }

            const clientIds = params?.clientIds || [];
            Promise.all(clientIds.map((clientId) => fetchData(clientId, { signal }))).catch(() => {});
        },
        [fetchData, isDisabled, params?.clientIds]
    );

    return {
        ...state,
        fetchData,
    };
}
