import { combineReducers } from 'helpers';
import { reducer as releaseReducer, initialState as releaseInitialState } from './Release/reducer';
import { reducer as campaignsReducer, initialState as campaignsInitialState } from './Campaigns/reducer';
import {
    reducer as instagramCampaignStatsReducer,
    initialState as instagramCampaignStatsInitialState,
} from './Campaigns/InstagramCampaigns/instagramCampaignStats';
import { reducer as creatorsReducer, initialState as creatorsInitialState } from './Creators/reducer';
import {
    reducer as instagramUserImagesReducer,
    initialState as instagramUserImagesInitialState,
} from 'Modules/Instagram/hooks/dataHooks/useInstagramUserImagesDataHook';
import {
    reducer as microwaveContentTagsReducer,
    initialState as microwaveContentTagsInitialState,
} from 'Modules/Tags/dataHooks/useMicrowaveContentTagsDataHook';

export type State = ReturnType<typeof reducer>;
export type Actions = Parameters<typeof reducer>[1];

export const initialState: State = {
    release: releaseInitialState,
    campaigns: campaignsInitialState,
    creators: creatorsInitialState,
    contentTags: microwaveContentTagsInitialState,
    instagram: {
        campaignStats: instagramCampaignStatsInitialState,
        userImages: instagramUserImagesInitialState,
    },
};

export const reducer = combineReducers({
    release: releaseReducer,
    campaigns: campaignsReducer,
    creators: creatorsReducer,
    contentTags: microwaveContentTagsReducer,
    instagram: combineReducers({
        campaignStats: instagramCampaignStatsReducer,
        userImages: instagramUserImagesReducer,
    }),
});
