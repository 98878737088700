import { DataState } from '@round/utils';
import { microwave } from '@round/api';
import { ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { makeLoadingStateSlice } from 'utility/dataState';

type State = {
    [instagramCampaignId: number]: DataState<microwave.InstagramCampaignStats | null> | undefined;
};

type Actions =
    | ReducerActionWithPayload<'loadInstagramCampaignStats', number[]>
    | ReducerActionWithPayload<
          'instagramCampaignStatsLoaded',
          { campaignId: number; stats: microwave.InstagramCampaignStats | null }
      >
    | ReducerActionWithPayload<'errorLoadingInstagramCampaignStats', { campaignId: number; error: string }>
    | ReducerAction<'resetInstagramCampaignStats'>;

export const initialState: State = {};

export const reducer = createReducer<State, Actions>({
    loadInstagramCampaignStats: (state, { payload: campaignIds }) => {
        const loadingState = makeLoadingStateSlice<number, microwave.InstagramCampaignStats | null>(campaignIds);
        return { ...state, ...loadingState };
    },
    errorLoadingInstagramCampaignStats: (state, { payload: { campaignId, error } }) => ({
        ...state,
        [campaignId]: {
            data: state[campaignId]?.data ?? null,
            status: 'error',
            error,
        },
    }),
    instagramCampaignStatsLoaded: (state, { payload: { campaignId, stats } }) => ({
        ...state,
        [campaignId]: {
            status: 'success',
            data: stats,
            error: null,
        },
    }),
    resetInstagramCampaignStats: () => initialState,
});
