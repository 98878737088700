import { creatorbase, youtube } from '@round/api';
import { Skeleton, TableProps, getTableMetaHelper, Image, SkeletonTableCell } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import styles from './PostsTable.module.css';
import StatusCell from 'Modules/Plans/components/StatusCell/StatusCell';
import CostCell from 'Modules/Plans/components/CostCell/CostCell';
import { UsePostsReturn } from 'Modules/Plans/Project/contexts/PostsContext';
import { CampaignTableRow } from '../ProjectCampaignsTable/ProjectCampaignsTable';
import moment from 'moment';
import TotalFooterCell from '../../../components/TotalFooterCell';
import TotalCostFooterCell from 'Modules/Plans/components/TotalCostFooterCell/TotalCostFooterCell';
import PostsNoDataLabel from '../PostsNoDataLabel/PostsNoDataLabel';
import ActionsCell from './components/ActionsCell/ActionsCell';
import NotesCell from 'Modules/Plans/components/NotesCell/NotesCell';
import { formatPostStatsCellValue, getYoutubeTotalEngagement } from 'Modules/Plans/Posts/helpers';
import PostsTable from 'Modules/Plans/Posts/components/PostsTable/PostsTable';
import { BasePostTableMeta } from 'Modules/Plans/Posts/types';
import { useYoutubeAccountsData } from 'Modules/Plans/Project/contexts/AccountDataContext/YoutubeAccountsContext/YoutubeAccountsContext';
import { useCampaignStats } from 'Modules/Plans/Project/contexts/CampaignStatsContext/CampaignsStatsContext';
import UpdateInfluencerPopout from './components/UpdateInfluencerPopout/UpdateInfluencerPopout';
import { isNumber } from '../../../../../../utility/utility';
import { toDecimalPoint } from '@round/utils';

export type YoutubePostsTableRow = creatorbase.YoutubePost & {
    channel: youtube.Channel | null;
};

type Props = Pick<TableProps<YoutubePostsTableRow>, 'data' | 'noDataLabel'> & {
    campaign: CampaignTableRow;
    isLoading?: boolean;
    hasError: boolean;
    onCreatePost: (campaign: CampaignTableRow) => void;
    onDeletePost: (post: YoutubePostsTableRow) => void;
    updatePost: UsePostsReturn['updatePost'];
};

type TableCellContext<TKey extends keyof YoutubePostsTableRow> = CellContext<
    YoutubePostsTableRow,
    YoutubePostsTableRow[TKey]
>;

type Meta = BasePostTableMeta<YoutubePostsTableRow> & {
    campaign: CampaignTableRow;
    onDeletePost: (post: YoutubePostsTableRow) => void;
};
const getTableMeta = getTableMetaHelper<Meta>();

const YoutubePostsTable = ({ data, campaign, isLoading, hasError, onCreatePost, onDeletePost, updatePost }: Props) => {
    const { getIsLoading: getAreCampaignStatsLoading } = useCampaignStats();
    const { getIsLoading: getIsAccountDataLoading } = useYoutubeAccountsData();

    const columns = useMemo<ColumnDef<YoutubePostsTableRow, any>[]>(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                cell: ({ getValue, table }: TableCellContext<'id'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return getValue().slice(-8);
                },
            },
            {
                header: 'Account',
                accessorKey: 'channel',
                cell: ({ getValue, row: { original }, table }) => {
                    const { isLoading, getIsAccountDataLoading, updatePost } = getTableMeta(table);

                    if (isLoading || getIsAccountDataLoading(original)) {
                        return <Skeleton />;
                    }

                    const channelName = getValue()?.title;

                    return (
                        <div className={styles.accountContainer}>
                            <Image loading={isLoading} src={getValue()?.thumbnail} className={styles.accountImage} />
                            <p className={styles.accountDetails}>{channelName ?? '-'}</p>

                            {!original.post_url && (
                                <UpdateInfluencerPopout
                                    key={channelName}
                                    className={styles.editAccountButton}
                                    accountHandle={channelName ?? ''}
                                    updateAccountHandle={
                                        updatePost
                                            ? (accountHandle) =>
                                                  updatePost?.(original.id, {
                                                      influencer_account_identifier: accountHandle,
                                                  })
                                            : undefined
                                    }
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                header: 'Status',
                id: 'status',
                cell: ({ row: { original }, table }: TableCellContext<'post_url'>) => {
                    const { isLoading, updatePost } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <StatusCell
                            post={original}
                            updatePost={updatePost ? (data) => updatePost(original.id, data) : undefined}
                        />
                    );
                },
            },
            {
                header: 'Cost',
                accessorKey: 'cost',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'cost'>) => {
                    const { isLoading, updatePost, campaign } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <CostCell
                            cost={getValue()}
                            currency={campaign.currency ?? null}
                            updateCost={
                                updatePost ? (cost: number | null) => updatePost(original.id, { cost }) : updatePost
                            }
                            isReadonly={!campaign.is_post_cost_editable}
                        />
                    );
                },
                footer: ({ table }) => {
                    const { isLoading, campaign } = getTableMeta(table);

                    return isLoading ? (
                        <Skeleton />
                    ) : (
                        <TotalCostFooterCell
                            costs={table.getRowModel().rows.map((r) => r.original.cost)}
                            currency={campaign.currency}
                        />
                    );
                },
            },
            {
                header: 'Uploaded date',
                accessorKey: 'upload_date',
                id: 'uploadedDate',
                cell: ({ getValue, table }: TableCellContext<'upload_date'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    const uploadedAt = getValue();

                    if (typeof uploadedAt !== 'string') {
                        return '-';
                    }

                    return moment(uploadedAt).format('DD MMM YYYY');
                },
            },
            {
                header: 'Views',
                accessorKey: 'view_count',
                cell: ({ getValue, table }: TableCellContext<'view_count'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return formatPostStatsCellValue(getValue());
                },
                footer: ({ table }) => {
                    const { isLoading, campaign } = getTableMeta(table);

                    return isLoading ? <Skeleton /> : formatPostStatsCellValue(campaign.stats?.view_count);
                },
            },
            {
                header: 'Total engagements',
                id: 'totalEngagements',
                accessorFn: (row) => formatPostStatsCellValue(getYoutubeTotalEngagement(row)),
                cell: SkeletonTableCell,
                footer: ({ table }) => {
                    const { isLoading } = getTableMeta(table);
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <TotalFooterCell
                            values={table.getRowModel().rows.map((r) => getYoutubeTotalEngagement(r.original))}
                        />
                    );
                },
            },
            {
                header: 'Engagement rate',
                id: 'engagementRate',
                accessorFn: (row) => {
                    const totalEngagements = getYoutubeTotalEngagement(row) ?? 0;
                    return isNumber(row.view_count)
                        ? `${toDecimalPoint((totalEngagements / row.view_count) * 100, 2)}%`
                        : '-';
                },
                cell: SkeletonTableCell,
                footer: ({ table }) => {
                    const { isLoading } = getTableMeta(table);
                    if (isLoading) {
                        return <Skeleton />;
                    }

                    const rows = table.getRowModel().rows;
                    const totalEngagements = rows.reduce((acc, row) => {
                        return acc + (getYoutubeTotalEngagement(row.original) ?? 0);
                    }, 0);

                    const views = rows.reduce((acc, row) => {
                        return acc + (row.original.view_count ?? 0);
                    }, 0);

                    return rows.every((row) => row.original.view_count === null)
                        ? '-'
                        : `${toDecimalPoint((totalEngagements / views) * 100, 2)}%`;
                },
            },
            {
                header: 'Likes',
                id: 'likes',
                cell: ({ row: { original }, table }) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    const likes = original.youtube_details?.like_count;

                    return formatPostStatsCellValue(likes);
                },
                footer: ({ table }) => {
                    const { isLoading } = getTableMeta(table);

                    return isLoading ? (
                        <Skeleton />
                    ) : (
                        <TotalFooterCell
                            values={table.getRowModel().rows.map((r) => r.original.youtube_details?.like_count)}
                        />
                    );
                },
            },
            {
                header: 'Comments',
                id: 'comments',
                cell: ({ row: { original }, table }) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    const comments = original.youtube_details?.comment_count;

                    return formatPostStatsCellValue(comments);
                },
                footer: ({ table }) => {
                    const { isLoading } = getTableMeta(table);

                    return isLoading ? (
                        <Skeleton />
                    ) : (
                        <TotalFooterCell
                            values={table.getRowModel().rows.map((r) => r.original.youtube_details?.comment_count)}
                        />
                    );
                },
            },
            {
                header: 'Notes',
                accessorKey: 'notes',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'notes'>) => {
                    const { isLoading, updatePost } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }
                    return (
                        <NotesCell
                            notes={getValue()}
                            updateNotes={updatePost ? (notes: string) => updatePost(original.id, { notes }) : undefined}
                        />
                    );
                },
            },
            {
                header: '',
                id: 'actions',
                cell: ({ row: { original }, table }) => {
                    const { isLoading, onDeletePost } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return <ActionsCell onDelete={() => onDeletePost(original)} />;
                },
            },
        ],
        []
    );

    // meta object is passed to every cell component. This allows us to reduce rerenders and prevents unnecessary cell unmounts.
    const meta: Meta = {
        isLoading: !!isLoading,
        getIsAccountDataLoading: (row) =>
            row.youtube_details?.account_id ? getIsAccountDataLoading(row.youtube_details.account_id) : false,
        areCampaignStatsLoading: getAreCampaignStatsLoading(campaign.id),
        onDeletePost,
        updatePost,
        campaign,
        campaignStats: campaign.stats,
    };

    return (
        <PostsTable
            className={styles.table}
            columns={columns}
            data={data}
            meta={meta}
            isLoading={isLoading}
            hasError={hasError}
            noDataLabel={<PostsNoDataLabel hasError={hasError} onAddPost={() => onCreatePost(campaign)} />}
        />
    );
};

export default YoutubePostsTable;
