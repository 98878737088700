import { creatorbase, Currency } from '@round/api';
import { getTableMetaHelper, ProgressBar, TableProps } from '@round/ui-kit';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './ReportCampaignsTable.module.css';
import TruncationPopover from 'ui-new/TruncationPopover/TruncationPopover';
import { asMoney, numberWithCommas, showNotification } from 'helpers';
import { toDecimalPoint } from '@round/utils';
import TooltipHeader from 'ui-new/whitelabel/Table/TooltipHeader/TooltipHeader';
import { tooltipCopy } from 'Modules/Plans/helpers';
import { AudioTimeSeriesData } from 'Modules/Plans/Project/contexts/AudioTimeSeriesContext/reducer';
import AudioGraph from 'Modules/Plans/components/AudioGraph/AudioGraph';
import ProjectCell, {
    Meta as ProjectCellMeta,
    ProjectData,
} from '../../../../Campaigns/components/cells/ProjectCell/ProjectCell';
import { CampaignsTableMeta } from '../../../../Campaigns/types';
import CampaignStatsCell from '../../../../Campaigns/components/cells/CampaignStatsCell';
import DurationCell from '../../../../Campaigns/components/cells/DurationCell';
import DragAndDropCampaignsTable from 'Modules/Plans/Campaigns/components/CampaignsTable/DragAndDropCampaignsTable';
import ExpandOrDragCell from 'Modules/Plans/Campaigns/components/cells/DragCell/ExpandOrDragCell';

export type CampaignTableRow = creatorbase.Campaign & {
    stats: creatorbase.AggregatedPostStats | null;
    project: creatorbase.Project | null;
    brand: creatorbase.Brand | null;
    team: creatorbase.Team | null;
    currency: Currency | undefined;
    audioTimeSeries?: AudioTimeSeriesData | null;
    audioUrl?: string;
};

type Props = Pick<
    TableProps<CampaignTableRow>,
    'data' | 'expandedState' | 'onExpandedChange' | 'renderSubComponent'
> & {
    hasError?: boolean;
    isLoading: boolean;
    meta: Meta;
    onDragEnd?: (newOrder: number[], currentOrder: number[]) => Promise<creatorbase.PatchReportResponse | undefined>;
};

type TableCellContext<TKey extends keyof CampaignTableRow> = CellContext<CampaignTableRow, CampaignTableRow[TKey]>;

type Meta = CampaignsTableMeta<CampaignTableRow> &
    Pick<ProjectCellMeta<CampaignTableRow>, 'getIsBrandLoading' | 'getIsProjectLoading' | 'getIsTeamLoading'>;
const getTableMeta = getTableMetaHelper<Meta>();

const ReportCampaignsTable = ({
    data,
    isLoading,
    hasError,
    expandedState,
    onExpandedChange,
    renderSubComponent,
    meta,
    onDragEnd,
}: Props) => {
    const columns = useMemo<ColumnDef<CampaignTableRow, any>[]>(
        () => [
            {
                header: '',
                id: 'expand',
                cell: ExpandOrDragCell,
            },
            {
                header: 'Projects',
                id: 'project',
                accessorFn: (row): ProjectData => ({
                    name: row.project?.name ?? '-',
                    brandImage: row.brand?.image,
                    brandName: row.brand?.name ?? '-',
                    teamName: row.team?.name ?? '-',
                }),
                cell: ProjectCell,
            },
            {
                header: 'Campaign',
                accessorKey: 'name',
                meta: {
                    className: styles.campaignColumn,
                },
                cell: ({ getValue, table }: TableCellContext<'name'>) => {
                    const { isLoading } = getTableMeta(table);

                    if (isLoading) {
                        return <Skeleton />;
                    }

                    return (
                        <TruncationPopover content={getValue()}>
                            {(setRef) => (
                                <p ref={setRef} className={styles.campaignName}>
                                    {getValue()}
                                </p>
                            )}
                        </TruncationPopover>
                    );
                },
            },
            {
                header: 'Posts',
                id: 'posts',
                accessorFn: (row) => row.stats?.post_count ?? '-',
                cell: CampaignStatsCell,
            },
            {
                header: 'Spend',
                accessorKey: 'stats',
                id: 'spend',
                cell: ({ getValue, row: { original }, table }: TableCellContext<'stats'>) => {
                    const { isLoading, getIsCampaignStatsLoading } = getTableMeta(table);

                    if (isLoading || getIsCampaignStatsLoading?.(original)) {
                        return <Skeleton />;
                    }

                    const stats = getValue();

                    const totalSpentCurrencyValuePair = Object.entries(stats?.total_spent ?? {})[0] ?? [];
                    const amount = totalSpentCurrencyValuePair[1];

                    const cost = original.cost;
                    const spentPercentage = cost ? (amount / cost) * 100 : null;

                    return (
                        <div className={styles.amountSpentContainer}>
                            <div className={styles.amountSpent}>
                                <span>{asMoney(amount, original.currency)}</span>
                                <span className={styles.spentPercentage}>
                                    {typeof spentPercentage === 'number'
                                        ? ` ${toDecimalPoint(spentPercentage, 2)}%`
                                        : '-'}
                                </span>
                            </div>
                            {typeof spentPercentage === 'number' && (
                                <ProgressBar
                                    progress={spentPercentage}
                                    trackClassName={styles.spentTrack}
                                    barClassName={styles.spentBar}
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                header: 'Views',
                id: 'views',
                accessorFn: (row) => (row.stats?.view_count ? numberWithCommas(row.stats.view_count) : '-'),
                cell: CampaignStatsCell,
            },
            {
                header: () => <TooltipHeader header="CPM" tooltipBody={tooltipCopy.cpm} />,
                id: 'cpm',
                accessorFn: (row) => {
                    const cpmCurrencyValuePair = Object.entries(row.stats?.cpm ?? {})[0] ?? [];
                    const amount = cpmCurrencyValuePair[1];

                    return asMoney(amount, row.currency);
                },
                cell: CampaignStatsCell,
            },
            {
                header: () => (
                    <TooltipHeader
                        header="ER"
                        tooltipTitle="Engagement Rate"
                        tooltipBody={tooltipCopy.engagementRate}
                    />
                ),
                id: 'er',
                accessorFn: (row) =>
                    typeof row.stats?.engagement_rate === 'number'
                        ? `${toDecimalPoint(row.stats.engagement_rate * 100, 2)}%`
                        : '-',
                cell: CampaignStatsCell,
            },
            {
                header: 'Duration',
                id: 'duration',
                cell: DurationCell,
            },
            {
                header: 'Audio',
                id: 'audioGraph',
                accessorKey: 'audioTimeSeries',
                meta: {
                    className: styles.dailyChange,
                },
                cell: ({ getValue, row: { original } }: TableCellContext<'audioTimeSeries'>) => {
                    const timeSeriesData = getValue();
                    const isTimeSeriesLoading = !!timeSeriesData && timeSeriesData.status === 'loading';

                    const timeSeries = timeSeriesData?.data?.posts_daily_change ?? [];
                    const shouldDisplayTimeSeriesData = !!timeSeries.length;
                    const points = timeSeries.map((s) => ({ x: s.timestamp, y: s.value }));
                    return (
                        <AudioGraph
                            audioUrl={original.audioUrl}
                            points={points}
                            shouldDisplayTimeSeriesData={shouldDisplayTimeSeriesData}
                            isTimeSeriesLoading={isTimeSeriesLoading}
                        />
                    );
                },
            },
        ],
        []
    );

    return (
        <DragAndDropCampaignsTable
            data={data}
            columns={columns}
            meta={meta}
            isLoading={isLoading}
            hasError={hasError}
            expandedState={expandedState}
            onExpandedChange={onExpandedChange}
            renderSubComponent={renderSubComponent}
            columnVisibility={{
                audioGraph: data.some((d) => d.audioTimeSeries),
            }}
            droppableId="report-campaigns"
            onDragEnd={async (result) => {
                const errorMessage = 'Could not reorder campaigns';

                try {
                    const currentOrder = data.map((row) => row.id);
                    const { source, destination } = result;
                    if (!destination) {
                        return;
                    }

                    const sourceIndex = source.index;
                    const destinationIndex = destination.index;

                    const newOrder = [...currentOrder];
                    const [removed] = newOrder.splice(sourceIndex, 1);
                    newOrder.splice(destinationIndex, 0, removed);

                    const response = await onDragEnd?.(newOrder, currentOrder);

                    if (response?.status !== 200) {
                        showNotification(errorMessage, 'error');
                        return;
                    }
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    showNotification(errorMessage, 'error');
                }
            }}
        />
    );
};

export default ReportCampaignsTable;
