import { DataState } from '@round/utils';
import { microwave } from '@round/api';
import { ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type State = DataState<microwave.Release>;
type Actions =
    | ReducerAction<'loadRelease'>
    | ReducerActionWithPayload<'releaseInitialized', microwave.Release>
    | ReducerActionWithPayload<'errorLoadingRelease', string>
    | ReducerAction<'resetRelease'>;

export const initialState: State = {
    data: null,
    status: 'idle',
    error: null,
};

export const reducer = createReducer<State, Actions>({
    loadRelease: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    errorLoadingRelease: (state, { payload }) => ({
        ...state,
        status: 'error',
        error: payload,
    }),
    releaseInitialized: (state, { payload }) => ({
        ...state,
        status: 'success',
        error: null,
        data: payload,
    }),
    resetRelease: () => initialState,
});
