import { microwave } from '@round/api';
import WrapperPaginationTable, {
    WrapperPaginationTableProps,
} from 'ui/WrapperTable/WrapperPaginationTable/WrapperPaginationTable';
import { GenericDropdownOption } from 'App.types';

export type CreatorsTableRow = microwave.MicrowaveInfluencer & {
    contentTagsOptions: GenericDropdownOption<number>[];
};

export type Props<TRow extends CreatorsTableRow> = Pick<
    WrapperPaginationTableProps<TRow>,
    'columns' | 'data' | 'page' | 'setPage' | 'pageSize' | 'setPageSize' | 'count' | 'noDataLabel'
> & {
    meta: Meta<TRow>;
};

export type Meta<TRow extends CreatorsTableRow = CreatorsTableRow> = {
    isLoading: boolean;
    getIsAccountDataLoading: (row: TRow) => boolean;
    updateCreator?: typeof microwave.patchMicrowaveInfluencer;
};

const CreatorsTable = <TRow extends CreatorsTableRow>({ data, ...props }: Props<TRow>) => {
    const rows = props.meta.isLoading && !data.length ? new Array(props.pageSize).fill(() => {}) : data;
    return <WrapperPaginationTable manualPagination data={rows} {...props} />;
};

export default CreatorsTable;
