import React, { FunctionComponent } from 'react';
import styles from './App.module.css';
import { BrowserRouter as Router, Route, Navigate, Routes, Outlet } from 'react-router-dom';
import {
    BrandAdmin,
    BuyPlatformAdmin,
    ChannelAdmin,
    ClientAdmin,
    CurrencyAdmin,
    FormatAdmin,
    ObjectiveAdmin,
    OptimisationMetricAdmin,
    ReleaseAdmin,
    FacebookAdAccountAdmin,
} from './Pages/Admin/AdminPages';
import DataUpload from './Pages/Admin/DataUpload/DataUpload';
import DataDownload from './Pages/Admin/DataDownload/DataDownload';
import { AuthProvider } from './Modules/Auth/contexts/AuthContext';
import AuthWrapper from './Modules/Auth/components/AuthWrapper/AuthWrapper';
import TableValidation from './Pages/Admin/TableValidation/TableValidation';
import OutstandingItems from './Pages/Admin/OutstandingItems/OutstandingItems';
import OutstandingMediaPlanItems from './Pages/Admin/OutstandingItems/OutstandingMediaPlanItems/OutstandingMediaPlanItems';
import TiktokArtist from './Pages/Explore/TiktokArtist/TiktokArtist';
import ReportItems from './Pages/Admin/ReportItems/ReportItems';
import ReportItemSpotify from './Pages/Admin/ReportItems/ReportItemSpotify/ReportItemSpotify';
import ReportItemYoutube from './Pages/Admin/ReportItems/ReportItemYoutube/ReportItemYoutube';
import InstagramArtist from './Pages/Explore/InstagramArtist/InstagramArtist';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ExploreTikTokHashtagsPage from './Pages/Explore/TikTokHashtags/ExploreTikTokHashtagsPage';
import AllCampaignsPage from './Pages/Campaigns/AllCampaignsPage/AllCampaignsPage';
import CampaignDetailsPage from './Pages/Campaigns/CampaignDetailsPage/CampaignDetailsPage';
import { UserGroup } from '@round/api';
import ProtectedRoute from './Modules/Auth/components/ProtectedRoute/ProtectedRoute';
import RedirectToDefault from './Modules/Navigation/components/RedirectToDefault/RedirectToDefault';
import TiktokInfluencerExplorePage from './Pages/Explore/TiktokInfluencerExplorePage/TiktokInfluencerExplorePage';
import { OptionsProvider } from './contexts/OptionsContext/OptionsContext';
import ReactTooltip from 'react-tooltip';
import InvoiceTrackerPage from './Pages/Finance/InvoiceTrackerPage/InvoiceTrackerPage';
import OutstandingInstagramInfluencerPosts from './Pages/Admin/OutstandingItems/OutstandingInfluencerPosts/OutstandingInstagramInfluencerPosts';
import OutstandingTiktokInfluencerPosts from './Pages/Admin/OutstandingItems/OutstandingInfluencerPosts/OutstandingTiktokInfluencerPosts';
import InstagramInfluencerExplorePage from './Pages/Influencer/InstagramList/InstagramInfluencerExplorePage';
import RoundClientUsersPage from './Pages/Admin/RoundClientUsers/RoundClientUsersPage';
import AuthPageLayout from './Modules/Auth/components/AuthPageLayout/AuthPageLayout';
import SignIn from './Modules/Auth/features/SignIn/SignIn';
import ForgotPassword from './Modules/Auth/features/ForgotPassword/ForgotPassword';
import ConfirmPassword from './Modules/Auth/features/ConfirmPassword/ConfirmPassword';
import TiktokInfluencerWideSearchPage from './Pages/Explore/TiktokWideSearchPage/TiktokInfluencerWideSearchPage';
import InstagramWideSearchPage from './Pages/Explore/InstagramWideSearchPage/InstagramWideSearchPage';
import MicrowaveInfluencers from './Modules/Advertising/Microwave/MicrowaveInfluencers/MicrowaveInfluencers';
import ApproveMicrowavePostsPage from './Pages/Microwave/ApprovePosts/ApproveMicrowavePostsPage';
import MicrowavePayments from './Pages/Microwave/MicrowavePayments/MicrowavePayments';
import PaymentsPage from './Pages/Finance/PaymentsPage/PaymentsPage';
import Playground from './Pages/Playground/Playground';
import { ClientProvider } from './contexts/ClientsContext';
import { ContentTagsProvider } from './contexts/ContentTagsContext';
import MicroCampaignsPage from './Pages/Microwave/MicrowaveCampaignsPage/MicroCampaignsPage';
import InvoiceRequestsPage from 'Pages/Finance/InvoiceRequestsPage/InvoiceRequestsPage';
import FeatureFlagsMenu from 'utility/featureFlags/FeatureFlagsMenu/FeatureFlagsMenu';
import MicrowaveCampaignDetailsPage from 'Pages/Microwave/CampaignDetailsPage/CampaignDetailsPage';
import WhiteLabelLayout from 'Pages/Whitelabel/Layout/WhiteLabelLayout';
import ProjectsList from 'Modules/Plans/Project/ProjectsList/ProjectsList';
import ProjectDetails from 'Modules/Plans/Project/ProjectDetails/ProjectDetails';
import WhiteLabelAuthWrapper from 'Modules/Auth/components/WhiteLabelAuthWrapper/WhiteLabelAuthWrapper';
import { ProjectProvider } from 'Modules/Plans/Project/contexts/ProjectContext';
import { CREATORBASE_ROOT_PATH } from 'Modules/Navigation/whitelabel/routes';
import SongsList from 'Modules/Plans/Songs/SongsList/SongsList';
import Report from 'Modules/Plans/Reports/Report/Report';
import { ReportProvider } from 'Modules/Plans/Reports/Report/ReportContext';
import ReportsList from 'Modules/Plans/Reports/ReportsList/ReportsList';
import PublicReport from 'Modules/Plans/PublicReports/PublicReport';
import WhiteLabelPublicLayout from 'Pages/Whitelabel/PublicLayout/WhiteLabelPublicLayout';
import { PublicReportProvider } from 'Modules/Plans/PublicReports/PublicReportContext';
import { ProjectDetailsProvider } from 'Modules/Plans/Project/ProjectDetails/ProjectDetailsContext';
import NavigateToMicrowaveAppForm from './Modules/Advertising/Microwave/PaymentRequest/NavigateToMicrowaveAppForm';

const adminPageMapping = [
    { urlString: 'client', Component: ClientAdmin, groups: ['user_admin'] },
    {
        urlString: 'facebook-ad-account',
        Component: FacebookAdAccountAdmin,
        groups: ['user_admin', 'facebook_api_editor'],
    },
    { urlString: 'brand', Component: BrandAdmin, groups: ['advertising_editor'] },
    { urlString: 'release', Component: ReleaseAdmin, groups: ['advertising_editor'] },
    { urlString: 'currency', Component: CurrencyAdmin, groups: ['user_admin'] },
    { urlString: 'channel', Component: ChannelAdmin, groups: ['user_admin'] },
    { urlString: 'buy-platform', Component: BuyPlatformAdmin, groups: ['user_admin'] },
    { urlString: 'objective', Component: ObjectiveAdmin, groups: ['user_admin'] },
    { urlString: 'format', Component: FormatAdmin, groups: ['user_admin'] },
    { urlString: 'optimisation-metric', Component: OptimisationMetricAdmin, groups: ['user_admin'] },
    { urlString: 'data-upload', Component: DataUpload, groups: ['user_admin'] },
    { urlString: 'data-download', Component: DataDownload, groups: ['round_super_admin'] },
    { urlString: 'table-validation', Component: TableValidation, groups: ['user_admin'] },
    { urlString: 'outstanding-items', Component: OutstandingItems, groups: ['user_admin'] },
    { urlString: 'outstanding-media-plan-items', Component: OutstandingMediaPlanItems, groups: ['user_admin'] },
    {
        urlString: 'outstanding-instagram-influencer-posts',
        Component: OutstandingInstagramInfluencerPosts,
        groups: ['user_admin'],
    },
    {
        urlString: 'outstanding-tiktok-influencer-posts',
        Component: OutstandingTiktokInfluencerPosts,
        groups: ['user_admin'],
    },
    {
        urlString: 'report-items',
        Component: ReportItems,
        groups: ['user_admin'],
    },
    {
        urlString: 'spotify',
        Component: ReportItemSpotify,
        groups: ['user_admin'],
    },
    {
        urlString: 'youtube',
        Component: ReportItemYoutube,
        groups: ['user_admin'],
    },
    {
        urlString: 'round-client-users',
        Component: RoundClientUsersPage,
        groups: ['round_super_admin'],
    },
] as { urlString: string; Component: FunctionComponent; groups: UserGroup[] }[];

function App() {
    return (
        <div className={styles.App}>
            <AuthProvider>
                <Router>
                    <Routes>
                        <Route index element={<RedirectToDefault />} />
                        <Route path="/explorer/tiktok/user/" element={<Navigate to="/artist/tiktok/list/" replace />} />
                        <Route path="/submit-post/:uuid" element={<NavigateToMicrowaveAppForm />} />
                        <Route
                            path={`/${CREATORBASE_ROOT_PATH}/public-reports/:uuid/`}
                            element={
                                <WhiteLabelPublicLayout>
                                    <PublicReportProvider>
                                        <PublicReport />
                                    </PublicReportProvider>
                                </WhiteLabelPublicLayout>
                            }
                        />

                        <Route
                            element={
                                <AuthPageLayout>
                                    <Outlet />
                                </AuthPageLayout>
                            }
                        >
                            <Route path="/login/" element={<SignIn />} />
                            <Route path="/forgot-password/" element={<ForgotPassword />} />
                            <Route path="/confirm-password/" element={<ConfirmPassword />} />
                        </Route>
                        <Route
                            element={
                                <AuthWrapper>
                                    <OptionsProvider>
                                        <ClientProvider>
                                            <ContentTagsProvider>
                                                <Outlet />
                                            </ContentTagsProvider>
                                        </ClientProvider>
                                    </OptionsProvider>
                                </AuthWrapper>
                            }
                        >
                            <Route
                                path={`/${CREATORBASE_ROOT_PATH}/`}
                                element={
                                    <WhiteLabelAuthWrapper>
                                        <WhiteLabelLayout>
                                            <Outlet />
                                        </WhiteLabelLayout>
                                    </WhiteLabelAuthWrapper>
                                }
                            >
                                <Route path="projects/" element={<ProjectsList />} />
                                <Route
                                    path="projects/:id/"
                                    element={
                                        <ProjectDetailsProvider>
                                            <ProjectProvider>
                                                <ProjectDetails />
                                            </ProjectProvider>
                                        </ProjectDetailsProvider>
                                    }
                                />

                                <Route path="songs/" element={<SongsList />} />

                                <Route path="reports/" element={<ReportsList />} />
                                <Route
                                    path="reports/:id/"
                                    element={
                                        <ReportProvider>
                                            <Report />
                                        </ReportProvider>
                                    }
                                />
                            </Route>

                            <Route
                                path="/explorer/tiktok/hashtag/"
                                element={
                                    <ProtectedRoute groups={['explorer_tiktok_viewer', 'user_admin']}>
                                        <ExploreTikTokHashtagsPage />
                                    </ProtectedRoute>
                                }
                            />

                            <Route
                                path="/influencer/tiktok/list/"
                                element={
                                    <ProtectedRoute groups={['plan_viewer', 'explorer_tiktok_viewer', 'user_admin']}>
                                        <TiktokInfluencerExplorePage />
                                    </ProtectedRoute>
                                }
                            />

                            <Route
                                path="/influencer/tiktok/search/"
                                element={
                                    <ProtectedRoute
                                        groups={['user_admin', 'wide_search_trial']}
                                        hasAccessWhenMatches="any"
                                    >
                                        <TiktokInfluencerWideSearchPage />
                                    </ProtectedRoute>
                                }
                            />

                            <Route
                                path="/influencer/instagram/search/"
                                element={
                                    <ProtectedRoute
                                        groups={['user_admin', 'wide_search_trial']}
                                        hasAccessWhenMatches="any"
                                    >
                                        <InstagramWideSearchPage />
                                    </ProtectedRoute>
                                }
                            />

                            <Route
                                path="/artist/tiktok/list/"
                                element={
                                    <ProtectedRoute groups={['explorer_tiktok_viewer', 'user_admin']}>
                                        <TiktokArtist />
                                    </ProtectedRoute>
                                }
                            />

                            <Route
                                path="/artist/instagram/list/"
                                element={
                                    <ProtectedRoute groups={['explorer_instagram_viewer', 'user_admin']}>
                                        <InstagramArtist />
                                    </ProtectedRoute>
                                }
                            />

                            <Route
                                path="/influencer/instagram/list/"
                                element={
                                    <ProtectedRoute groups={['plan_viewer', 'explorer_instagram_viewer', 'user_admin']}>
                                        <InstagramInfluencerExplorePage />
                                    </ProtectedRoute>
                                }
                            />

                            <Route
                                path="/campaigns/"
                                element={
                                    <ProtectedRoute groups={['plan_viewer']} hasAccessWhenMatches="any">
                                        <AllCampaignsPage />
                                    </ProtectedRoute>
                                }
                            />

                            <Route
                                path="/campaigns/:releaseId/*"
                                element={
                                    <ProtectedRoute groups={['plan_viewer']} hasAccessWhenMatches="any">
                                        <CampaignDetailsPage />
                                    </ProtectedRoute>
                                }
                            />

                            <Route path="/microwave/">
                                <Route
                                    path="influencers"
                                    element={
                                        <ProtectedRoute groups={['microwave_admin']}>
                                            <MicrowaveInfluencers />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="approve-posts/"
                                    element={
                                        <ProtectedRoute groups={['microwave_admin']}>
                                            <ApproveMicrowavePostsPage />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="payments"
                                    element={
                                        <ProtectedRoute
                                            groups={['microwave_finance', 'microwave_admin']}
                                            hasAccessWhenMatches="any"
                                        >
                                            <MicrowavePayments />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="campaigns"
                                    element={
                                        <ProtectedRoute groups={['microwave_admin']} hasAccessWhenMatches="all">
                                            <MicroCampaignsPage />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="/microwave/campaigns/:releaseId/*"
                                    element={
                                        <ProtectedRoute groups={['microwave_admin']} hasAccessWhenMatches="any">
                                            <MicrowaveCampaignDetailsPage />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>

                            <Route path="/finance/">
                                <Route
                                    path="invoice-requests"
                                    element={
                                        <ProtectedRoute groups={['round_planner']}>
                                            <InvoiceRequestsPage />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="invoice-tracker"
                                    element={
                                        <ProtectedRoute groups={['user_admin', 'finance_viewer']}>
                                            <InvoiceTrackerPage />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="payments/"
                                    element={
                                        <ProtectedRoute
                                            groups={['round_finance', 'round_planner']}
                                            hasAccessWhenMatches="any"
                                        >
                                            <PaymentsPage />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>

                            <Route path="/advertising/*" element={<Navigate to="/campaigns/" replace />} />
                            <Route path="/admin/*">
                                {adminPageMapping.map(({ urlString, Component, groups }) => (
                                    <Route
                                        key={urlString}
                                        path={urlString}
                                        element={
                                            <ProtectedRoute groups={groups}>
                                                <Component />
                                            </ProtectedRoute>
                                        }
                                    />
                                ))}
                            </Route>
                            <Route path="/playground/" element={<Playground />} />
                        </Route>
                    </Routes>
                </Router>
            </AuthProvider>
            <ToastContainer />
            <ReactTooltip id="global" />
            <FeatureFlagsMenu />
        </div>
    );
}

export default App;
