import useCreators from '../useCreators';
import { ReleaseContext } from '../../ReleaseContext';
import { makeInstagramUserImagesDataHook } from 'Modules/Instagram/hooks/dataHooks/useInstagramUserImagesDataHook';
import { isNumber } from 'utility/utility';
import { useEffect } from 'react';

const useInstagramUserImages = makeInstagramUserImagesDataHook(ReleaseContext, ([state]) => state.instagram.userImages);

export default function useInstagramCreators(...[campaignId, params]: Parameters<typeof useCreators>) {
    const { data, status, ...restOfState } = useCreators(campaignId, { ...params, has_instagram_account: true });
    const { data: instagramUserImagesData, fetchData: fetchInstagramUserImages } = useInstagramUserImages();

    const areCreatorsSuccessfullyInitialized = status === 'success';
    useEffect(() => {
        const instagramUserIds = data?.results.map((creator) => creator.instagram_user_id).filter(isNumber) ?? [];
        const userIdsWithImages = Object.keys(instagramUserImagesData).map(Number);
        const userIdsToFetchImagesFor = instagramUserIds.filter((userId) => !userIdsWithImages.includes(userId));
        if (!areCreatorsSuccessfullyInitialized || !userIdsToFetchImagesFor.length) {
            return;
        }

        fetchInstagramUserImages(userIdsToFetchImagesFor).catch(() => {});
    }, [areCreatorsSuccessfullyInitialized, data?.results, fetchInstagramUserImages, instagramUserImagesData]);

    return {
        data,
        status,
        ...restOfState,
        userImages: instagramUserImagesData,
        getIsAccountDataLoading: (userId: number | null) => {
            return userId !== null && instagramUserImagesData[userId]?.status === 'loading';
        },
    };
}
