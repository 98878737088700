import { CreatorsTableRow, Meta } from '../CreatorsTable';
import { CellContext } from '@tanstack/react-table';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import Select from 'ui-new/Select/Select';
import { StylesConfig } from 'react-select';
import useMicrowaveContentTags from '../../useMicrowaveContentTags';
import { GenericDropdownOption } from 'App.types';
import { useState } from 'react';
import { showNotification } from 'helpers';

type TagsData = CreatorsTableRow['contentTagsOptions'];
const getTableMeta = getTableMetaHelper<Meta>();

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        border: '1px solid transparent',
        padding: '0.5rem',
        maxWidth: '15rem',
        minWidth: '10rem',
    }),
    menu: (base) => ({
        ...base,
        minWidth: 'max-content',
    }),
};

const TagsCell = <TRow extends CreatorsTableRow>({
    getValue,
    table,
    row: { original },
}: CellContext<TRow, TagsData>) => {
    const { isLoading, updateCreator } = getTableMeta(table);
    const value = getValue();
    const { data: contentTags, status } = useMicrowaveContentTags();
    const options: GenericDropdownOption<number>[] = contentTags?.map((t) => ({ value: t.id, label: t.name })) ?? [];

    const [isUpdating, setIsUpdating] = useState(false);

    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <Select
            isLoading={status === 'loading'}
            styles={selectStyles}
            isMulti
            hideSelectedOptions={false}
            isDisabled={isUpdating}
            placeholder="Search tags"
            noOptionsMessage={() => 'No tags found.'}
            value={value}
            options={options}
            onChange={(value) => {
                setIsUpdating(true);
                updateCreator?.(original.id, { content_tags: value?.map((v) => v.value) ?? [] })
                    .then((response) => {
                        if (response.status === 200) {
                            showNotification('Tags updated', 'info');
                            return;
                        }

                        const error =
                            response.status === 404 ? 'Could not find creator' : response.data.content_tags?.toString();
                        showNotification(error || 'Could not update tags', 'error');
                    })
                    .catch(() => {
                        showNotification('Could not update tags', 'error');
                    })
                    .finally(() => {
                        setIsUpdating(false);
                    });
            }}
        />
    );
};

export default TagsCell;
