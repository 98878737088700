import { getTiktokHashTags, GetTiktokHashtagsParams, TiktokHashtag } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import { OptionsParams, UseMultiValueSelectParams, useSelect } from 'Hooks/useSelect';
import { useMemo } from 'react';

export type TiktokHashtagOption = GenericDropdownOption<number> & {
    tiktok_id: string;
    description: string;
};

export const mapTiktokHashtagToOption = (hashtag: TiktokHashtag): TiktokHashtagOption => ({
    value: hashtag.id,
    label: hashtag.title,
    tiktok_id: hashtag.tiktok_id,
    description: hashtag.desc,
});

type MultiParams = Omit<UseMultiValueSelectParams<TiktokHashtagOption>, 'fetchOptions' | 'isMulti'> & {
    fetchOptions?: UseMultiValueSelectParams<TiktokHashtagOption>['fetchOptions'];
};

const fetchOptions = async (params: GetTiktokHashtagsParams, requestInit?: RequestInit) => {
    const response = await getTiktokHashTags(params, requestInit);

    if (response.status !== 200) {
        throw new Error('Could not get tiktok hashtags');
    }

    return {
        ...response.data,
        results: response.data.results.map(mapTiktokHashtagToOption),
    };
};

export const buildTiktokHashtagSelectFetchOptions = (tagParams: GetTiktokHashtagsParams) => (
    params: OptionsParams,
    requestInit?: RequestInit
) => fetchOptions({ ...tagParams, ...params }, requestInit);

const fetchInitialValues = async (ids: number[], requestInit?: RequestInit) => {
    const response = await getTiktokHashTags({ id: ids.join(',') }, requestInit);

    if (response.status !== 200) {
        throw new Error('Could not get tiktok hashtags');
    }

    return response.data.results.map(mapTiktokHashtagToOption);
};

export default function useTiktokHashtagMultiSelect(params: MultiParams) {
    const defaultFetchOptions = useMemo(() => buildTiktokHashtagSelectFetchOptions({}), []);
    const fetchOptions = params.fetchOptions || defaultFetchOptions;

    return useSelect<TiktokHashtagOption>({
        isMulti: true,
        fetchOptions,
        fetchInitialValue: fetchInitialValues,
        ...params,
    });
}
