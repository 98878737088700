import { useCurrentCreatorbaseUser } from 'Modules/Auth/hooks/useCurrentCreatorbaseUser';
import useUser from 'Modules/Plans/User/hooks/useUser';
import useClients from './useClients';

export default function useCurrentUserClients() {
    const { creatorbaseUserId } = useCurrentCreatorbaseUser();
    const { data: user } = useUser(creatorbaseUserId);
    const { data: clients, status, error } = useClients({
        params: { clientIds: user?.client_ids },
        isDisabled: !user?.client_ids.length,
    });

    return { clients, status, error };
}
