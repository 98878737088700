import { AppServer } from '../../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Client } from './clients.types';
import { createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    creatorbaseClient: Model as ModelDefinition<Client>,
};

export const serializers = {
    creatorbaseClient: makeSerializer<Client>([]),
};

export const factories = {
    creatorbaseClient: createFactory<Client>({
        name: (index: number) => `name ${index}`,
        logo: null,
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/creatorbase/clients/:id/', (schema, request) => {
        const client = schema.find('creatorbaseClient', request.params.id);
        if (!client) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return client;
    });
}
