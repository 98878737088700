import { HTMLProps, PropsWithChildren } from 'react';
import { getPanelId, getTabId } from '../../helpers';
import useNonNullContext from '../../../../hooks/useNonNullContext';
import { TabsContext } from '../../Tabs';

type TabPanelProps = PropsWithChildren<HTMLProps<HTMLDivElement> & { name: string; className?: string }>;

const TabPanel = ({ children, name, className, ...props }: TabPanelProps) => {
    const { activeTab } = useNonNullContext(TabsContext);
    const isSelected = activeTab === name;

    if (!isSelected) {
        return null;
    }

    return (
        <div
            role="tabpanel"
            id={getPanelId(name)}
            tabIndex={0}
            aria-labelledby={getTabId(name)}
            className={className}
            {...props}
        >
            {children}
        </div>
    );
};

export default TabPanel;
