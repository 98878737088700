import React, { useMemo } from 'react';
import { CategoriesFilterCondition } from '../helpers';
import {
    AdvancedFilter,
    AdvancedFilterCondition,
    AdvancedFilterConditionTypeOption,
    conditionTypeOptions,
} from '@round/ui-kit';
import { GenericDropdownOption } from '../../../../../App.types';
import { ValueType } from 'react-select';

type Props = {
    conditions: CategoriesFilterCondition[];
    onChange: (value: CategoriesFilterCondition[]) => void;
    className?: string;
    options: GenericDropdownOption<number>[];
    optionsLoading?: boolean;
    getLabel?: (isFiltered: boolean) => string;
    conditionOptions?: AdvancedFilterConditionTypeOption[];
};

const CategoriesFilter = ({
    conditions,
    onChange,
    className,
    options,
    optionsLoading,
    getLabel = (isFiltered) => (isFiltered ? 'Filtered by categories' : 'Categories'),
    conditionOptions = conditionTypeOptions,
}: Props) => {
    const mappedConditions: AdvancedFilterCondition<ValueType<GenericDropdownOption<number>, true>>[] = useMemo(
        () =>
            conditions.map((condition) => {
                const tags = condition.value
                    .map((tagId) => options.find((tag) => tag.value === tagId))
                    .filter((tag): tag is GenericDropdownOption<number> => !!tag);

                return {
                    type: condition.type,
                    value: tags,
                };
            }),
        [conditions, options]
    );

    return (
        <AdvancedFilter
            className={className}
            conditions={mappedConditions}
            conditionOptions={conditionOptions}
            onChange={(conditions) =>
                onChange(
                    conditions.map((condition) => ({
                        ...condition,
                        value: condition.value?.map((tag) => tag.value) ?? [],
                    }))
                )
            }
            options={options}
            optionsLoading={optionsLoading}
            getLabel={getLabel}
        />
    );
};

export default CategoriesFilter;
