import useAbortableEffect from 'Hooks/useAbortableEffect';
import ProjectsTable, { ProjectsTableRow } from './components/ProjectsTable/ProjectsTable';
import { useProjectsList } from './useProjectsList';
import styles from './ProjectsList.module.css';
import { useState } from 'react';
import CreateProjectModal from './components/CreateProjectModal/CreateProjectModal';
import SearchInput from 'ui-new/whitelabel/SearchInput/SearchInput';
import { creatorbase } from '@round/api';
import useUrlState from 'Hooks/useUrlState';
import { Checkbox, ScrollView } from '@round/ui-kit';
import { useCurrentCreatorbaseUser } from 'Modules/Auth/hooks/useCurrentCreatorbaseUser';
import Button from 'ui-new/whitelabel/Button/Button';
import SongDataModal from 'Modules/Plans/Songs/SongDataModal/SongDataModal';
import { SongOption, useSongMultiSelect } from 'Modules/Plans/Songs/hooks/useSongSelect';
import Select from 'ui-new/whitelabel/Select/Select';
import { FormatOptionLabelMeta, StylesConfig, ValueType } from 'react-select';
import SongBadge from 'Modules/Plans/Songs/components/SongBadge/SongBadge';
import DeleteProjectModal from './components/DeleteProjectModal/DeleteProjectModal';

type UrlState = Partial<
    Pick<creatorbase.GetProjectsParams, 'search'> & {
        my_projects: string;
        song: string;
        client_song_id: string;
    }
>;

const ProjectsList = () => {
    const { creatorbaseUserId } = useCurrentCreatorbaseUser();
    const [urlState, setUrlState] = useUrlState<UrlState>();

    const isMyProjectsFilterActive = urlState.my_projects === 'true';
    const myProjectsFilterValue =
        isMyProjectsFilterActive && creatorbaseUserId ? creatorbaseUserId.toString() : undefined;

    const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);
    const [isDeleteProjectModalOpen, setIsDeleteProjectModalOpen] = useState(false);

    const [projectRowToDelete, setProjectRowToDelete] = useState<ProjectsTableRow | null>(null);

    const onClickDeleteProject = (project: ProjectsTableRow) => {
        setProjectRowToDelete(project);
        setIsDeleteProjectModalOpen(true);
    };

    const [lastFetchedPage, setLastFetchedPage] = useState<number>();
    const {
        rows,
        hasNextPage,
        fetchData,
        createProject,
        status,
        reset: resetProjects,
        deleteProject,
    } = useProjectsList();
    const isProjectsInitialized = status === 'success' || status === 'error';

    const { props: songSelectProps } = useSongMultiSelect({
        initOn: 'menuOpen',
        initialValueId: urlState.client_song_id?.split(',').map((id) => Number(id)),
    });

    useAbortableEffect(
        (signal) => {
            if (!isProjectsInitialized && typeof lastFetchedPage !== 'number') {
                fetchData(
                    {
                        page: lastFetchedPage ?? 1,
                        page_size: 25,
                        search: urlState.search,
                        users_assigned_to_campaigns: myProjectsFilterValue,
                        client_song_id: urlState.client_song_id,
                    },
                    { signal }
                )
                    .then(() => {
                        setLastFetchedPage(1);
                    })
                    .catch(() => {});
            }
        },
        [
            fetchData,
            urlState.search,
            myProjectsFilterValue,
            isProjectsInitialized,
            lastFetchedPage,
            urlState.client_song_id,
            songSelectProps.value,
        ]
    );

    const songSelectStyles: StylesConfig = {
        container: (base) => ({
            ...base,
            minWidth: '20rem',
            maxWidth: '30rem',
        }),
        valueContainer: (base) => ({
            ...base,
            flexWrap: 'nowrap',
            overflowX: 'auto',
        }),
        multiValue: (base) => ({
            ...base,
            minWidth: 'min-content',
        }),
        menu: (base) => ({
            ...base,
            width: '100%',
            minWidth: '20rem',
            '@media only screen and (min-width: 768px)': {
                width: 'max-content',
            },
        }),
    };

    return (
        <>
            <div className={styles.container}>
                <header className={styles.header}>
                    <h1 className={styles.title}>Projects</h1>

                    <Button appearance="primary" onClick={() => setIsCreateProjectModalOpen(true)}>
                        Create project
                    </Button>
                </header>

                <menu className={styles.menuBar}>
                    <SearchInput
                        value={urlState.search ?? ''}
                        onChange={(e) => {
                            setUrlState({ search: e.target.value });
                            resetProjects();
                            setLastFetchedPage(undefined);
                        }}
                        className={styles.search}
                    />

                    <Select
                        {...songSelectProps}
                        placeholder="Filter by song..."
                        styles={songSelectStyles}
                        onChange={(value, option) => {
                            songSelectProps.onChange?.(value, option);
                            setUrlState({ client_song_id: value?.map((obj) => obj.value).join(',') });
                            resetProjects();
                            setLastFetchedPage(undefined);
                        }}
                        value={songSelectProps.value as ValueType<SongOption, true>}
                        formatOptionLabel={(option: SongOption, meta: FormatOptionLabelMeta<SongOption, true>) =>
                            meta.context === 'menu' ? (
                                <SongBadge
                                    title={option.label}
                                    artistName={option.artistName}
                                    imageUrl={option.image}
                                />
                            ) : (
                                option.label
                            )
                        }
                        isMulti
                    />

                    <div className={styles.myProjectsFilter}>
                        <Checkbox
                            value={isMyProjectsFilterActive}
                            onChange={() => {
                                setUrlState({ my_projects: isMyProjectsFilterActive ? undefined : 'true' });
                                setLastFetchedPage(undefined);
                                resetProjects();
                            }}
                        />
                        <label
                            //checkbox component isn't an input, so id and htmlFor can't currently be used
                            onClick={() => {
                                setUrlState({ my_projects: isMyProjectsFilterActive ? undefined : 'true' });
                                setLastFetchedPage(undefined);
                                resetProjects();
                            }}
                        >
                            Only my Projects
                        </label>
                    </div>
                </menu>

                <ScrollView className={styles.tableContainer}>
                    <ProjectsTable
                        data={rows}
                        isLoading={!isProjectsInitialized}
                        hasError={status === 'error'}
                        onSongChartClick={(songId) => {
                            setUrlState({ song: songId.toString() });
                        }}
                        onDeleteProject={(campaign) => onClickDeleteProject(campaign)}
                        onEndReached={() => {
                            if (status === 'success' && hasNextPage) {
                                fetchData({
                                    page: (lastFetchedPage ?? 0) + 1,
                                    page_size: 25,
                                    search: urlState.search,
                                    users_assigned_to_campaigns: myProjectsFilterValue,
                                })
                                    .then(() => {
                                        setLastFetchedPage((page) => (page ?? 0) + 1);
                                    })
                                    .catch(() => {});
                            }
                        }}
                    />
                </ScrollView>
            </div>

            <CreateProjectModal
                isOpen={isCreateProjectModalOpen}
                onClose={() => setIsCreateProjectModalOpen(false)}
                createProject={(data) => {
                    return createProject(data).then((response) => {
                        if (response.status === 201) {
                            setLastFetchedPage(undefined);
                        }

                        return response;
                    });
                }}
            />
            <DeleteProjectModal
                project={projectRowToDelete}
                isOpen={isDeleteProjectModalOpen}
                onClose={() => {
                    setIsDeleteProjectModalOpen(false);
                    setProjectRowToDelete(null);
                }}
                deleteProject={(data) => {
                    return deleteProject(data).then((response) => {
                        if (response.status === 204) {
                            setLastFetchedPage(undefined);
                        }

                        return response;
                    });
                }}
            />

            <SongDataModal
                songId={urlState.song ? Number(urlState.song) : undefined}
                isOpen={!!urlState.song}
                onClose={() => {
                    setUrlState({ song: undefined });
                }}
            />
        </>
    );
};

export default ProjectsList;
