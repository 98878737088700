import InstagramCreatorsFilters, { getFilterValues } from './Filters/InstagramCreatorsFilters';
import InstagramCreatorsTable, { InstagramCreatorsTableRow } from './InstagramCreatorsTable/InstagramCreatorsTable';
import useUrlState from 'Hooks/useUrlState';
import useInstagramCreators from './useInstagramCreators';
import { useMemo } from 'react';
import { microwave } from '@round/api';
import {
    isExcludeCondition,
    isIncludeCondition,
    mapConditionsToApi,
    mapExcludeConditionsToApi,
    parseConditionsFromUrlString,
} from 'Modules/Tags/components/Filters/helpers';
import useMicrowaveContentTags from '../useMicrowaveContentTags';
import { toDecimalPoint } from '@round/utils';

type Props = {
    campaignId: number;
};

type UrlState = Omit<Partial<microwave.GetMicrowaveInfluencersParams>, 'ordering'>;

const initialUrlState: Pick<Required<UrlState>, 'page' | 'page_size'> = {
    page: 1,
    page_size: 10,
};

const InstagramCreators = ({ campaignId }: Props) => {
    // would probably be replaced with a solution that includes filters as well
    const [urlState, setUrlState] = useUrlState<UrlState>(initialUrlState);
    const page = urlState.page ? Number(urlState.page) : initialUrlState.page;
    const pageSize = urlState.page_size ? Number(urlState.page_size) : initialUrlState.page_size;

    const { data, status, error, reset, userImages, getIsAccountDataLoading, updateCreator } = useInstagramCreators(
        campaignId,
        {
            page: page,
            page_size: pageSize,
            search: urlState.search,
            content_tags: JSON.stringify(
                mapConditionsToApi(parseConditionsFromUrlString(urlState.content_tags).filter(isIncludeCondition))
            ),
            exclude_content_tags: JSON.stringify(
                mapExcludeConditionsToApi(
                    parseConditionsFromUrlString(urlState.content_tags).filter(isExcludeCondition)
                )
            ),
            hashtag_ids: urlState.hashtag_ids,
            rating: urlState.rating,
            min_tiktok_post_count: urlState.min_tiktok_post_count ? Number(urlState.min_tiktok_post_count) : undefined,
            max_tiktok_post_count: urlState.max_tiktok_post_count ? Number(urlState.max_tiktok_post_count) : undefined,
            min_tiktok_post_invite_count: urlState.min_tiktok_post_invite_count
                ? Number(urlState.min_tiktok_post_invite_count)
                : undefined,
            max_tiktok_post_invite_count: urlState.max_tiktok_post_invite_count
                ? Number(urlState.max_tiktok_post_invite_count)
                : undefined,
            min_tiktok_post_rate: urlState.min_tiktok_post_rate
                ? toDecimalPoint(Number(urlState.min_tiktok_post_rate) / 100, 2)
                : undefined,
        }
    );

    const { data: contentTags } = useMicrowaveContentTags();

    const rows: InstagramCreatorsTableRow[] = useMemo(
        () =>
            data?.results.map((creator) => {
                const image = creator.instagram_user_id ? userImages[creator.instagram_user_id]?.data ?? null : null;
                const creatorTags = contentTags?.filter((tag) => creator.content_tags.includes(tag.id)) ?? [];

                return {
                    ...creator,
                    instagramUserImage: image,
                    contentTagsOptions: creatorTags.map((tag) => ({ value: tag.id, label: tag.name })),
                };
            }) ?? [],
        [contentTags, data?.results, userImages]
    );

    return (
        <>
            <InstagramCreatorsFilters
                value={getFilterValues(urlState)}
                onChange={(filterVals, shouldRefreshData = true) => {
                    if (shouldRefreshData === false) {
                        setUrlState({ ...filterVals });
                        return;
                    }
                    setUrlState({ ...filterVals, page: 1 });
                    reset();
                }}
            />

            <InstagramCreatorsTable
                data={rows}
                count={data?.count ?? 0}
                page={page}
                setPage={(page) => setUrlState({ page })}
                pageSize={pageSize}
                setPageSize={(pageSize) => setUrlState({ page_size: pageSize })}
                noDataLabel={!data?.results.length && (status === 'error' ? error : 'No data')}
                meta={{
                    isLoading: status === 'loading',
                    getIsAccountDataLoading: (row) => getIsAccountDataLoading(row.instagram_user_id),
                    updateCreator,
                }}
            />
        </>
    );
};

export default InstagramCreators;
