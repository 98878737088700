import { useParams } from 'react-router-dom';
import { MICROWAVE_APP_DOMAIN } from '../MicroCreators/helpers';
import { useLayoutEffect } from 'react';

const NavigateToMicrowaveAppForm = () => {
    const { uuid } = useParams<{ uuid: string }>();

    useLayoutEffect(() => {
        window.location.replace(`${MICROWAVE_APP_DOMAIN}/submit-post/${uuid}/`);
    }, [uuid]);

    return null;
};

export default NavigateToMicrowaveAppForm;
