export async function fetchFile(url: string) {
    const response = await fetch(url);
    if (response.status === 404 || response.status === 401 || response.status === 403) {
        return {
            status: response.status,
            data: response.statusText,
        };
    }

    const fileName = url.split('/').reverse()[0];

    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
}
