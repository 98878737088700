import { useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import styles from './ExpandableSearchInput.module.css';
import SearchInput, { SearchInputProps } from 'ui-new/SearchInput/SearchInput';

type Props = SearchInputProps & {
    persistIfValuePresent?: boolean;
};

const ExpandableSearchInput = ({ value, persistIfValuePresent, ...props }: Props) => {
    const [isExpanded, setIsExpanded] = useState((value?.length ?? 0) > 0 && persistIfValuePresent);
    const input = useRef<HTMLInputElement>(null);

    return isExpanded ? (
        <SearchInput
            value={value}
            onBlur={() => {
                if (value?.length && persistIfValuePresent) {
                    return;
                }
                setIsExpanded(false);
            }}
            {...props}
            inputRef={input}
            autoFocus
        />
    ) : (
        <div
            onClick={() => {
                setIsExpanded(true);
            }}
            className={styles.container}
        >
            <SearchIcon className={styles.icon} />
        </div>
    );
};

export default ExpandableSearchInput;
