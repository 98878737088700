import useCampaigns from '../useCampaigns';

type Props = {
    releaseId: number;
};

const TiktokCampaigns = ({ releaseId }: Props) => {
    const { data: campaigns } = useCampaigns(releaseId);

    return (
        <>
            {campaigns
                ?.filter((campaign) => campaign.platform === 'tiktok')
                .map((campaign) => (
                    <div key={campaign.id}>{campaign.name}</div>
                ))}
        </>
    );
};

export default TiktokCampaigns;
