import React from 'react';
import styles from './Rating.module.css';
import { ReactComponent as StarLinearIcon } from '../../assets/StarLinear.svg';
import { ReactComponent as StarIcon } from '../../assets/Star.svg';
import range from 'lodash/range';
import cn from 'classnames';

export type StarButtonRenderProps = {
    rating: number;
    isSelected: boolean;
    isDisabled: boolean;
    onChange?: (rating: number) => void;
};

type Props = {
    rating: number;
    onChange?: (rating: number) => void;
    renderStarButton?: (props: StarButtonRenderProps) => React.ReactNode;
    maxRating: number;
    disabled?: boolean;
};

const defaultRenderStarButton = ({ rating, isSelected, isDisabled, onChange }: StarButtonRenderProps) => {
    return (
        <button
            key={rating}
            className={cn(styles.star, { [styles.selected]: isSelected, [styles.disabled]: isDisabled })}
            onClick={() => {
                if (!isDisabled && typeof onChange === 'function') {
                    onChange(rating);
                }
            }}
        >
            {isSelected ? <StarIcon /> : <StarLinearIcon />}
        </button>
    );
};

const Rating = ({ rating, onChange, maxRating, disabled, renderStarButton = defaultRenderStarButton }: Props) => {
    return (
        <div className={styles.container}>
            {range(1, maxRating + 1).map((i) => {
                const isSelected = i <= rating;
                return renderStarButton({ rating: i, isSelected, isDisabled: !!disabled, onChange });
            })}
        </div>
    );
};

export default Rating;
