import React, { useMemo, useState } from 'react';
import { microwave } from '@round/api';
import PaginationTable, { PaginationTableProps } from '../../../../../../../ui/PaginationTable/PaginationTable';
import { CellProps, Column } from 'react-table';
import { Button, Checkbox, Skeleton } from '@round/ui-kit';
import styles from './ContactedTable.module.css';
import { ReactComponent as TimerIcon } from '../../../../../../..//assets/Timer.svg';
import DeadlineCell from './DeadlineCell/DeadlineCell';
import TiktokUserBadge from '../../../../../../TikTok/components/TiktokUserBadge/TiktokUserBadge';
import { TiktokPostInviteData } from '../useTiktokPostInvites';
import { ReactComponent as LinkIcon } from 'assets/icons/Link.svg';
import cn from 'classnames';
import { MICROWAVE_APP_DOMAIN } from '../../../helpers';

type Props = Omit<PaginationTableProps<TiktokPostInviteData>, 'columns'> & {
    selectedRowIds: number[];
    onRowSelect: (row: TiktokPostInviteData) => void;
    onAllRowsSelect: (rows: TiktokPostInviteData[]) => void;
    updatePostInvite: typeof microwave.patchTiktokMicroPostInvite;
    onEmailHistoryClicked: (row: TiktokPostInviteData) => void;
};

const formatDateTime = (datetime: string) =>
    new Date(datetime).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    });

const ContactedTable = ({
    selectedRowIds,
    onRowSelect,
    onAllRowsSelect,
    updatePostInvite,
    onEmailHistoryClicked,
    ...props
}: Props) => {
    const [multiSelectAnchorRowId, setMultiSelectAnchorRowId] = useState<number | null>(null);

    const columns: Column<TiktokPostInviteData>[] = useMemo(
        () => [
            {
                Header: () => (
                    <div>
                        <Checkbox
                            ariaLabel="Select all"
                            value={
                                props.data.length > 0 && props.data.every((row) => selectedRowIds.includes(row.user_id))
                            }
                            onChange={() => onAllRowsSelect(props.data)}
                        />
                    </div>
                ),
                id: 'select',
                disableSortBy: true,
                Cell: ({ row: { original } }: CellProps<TiktokPostInviteData>) => {
                    return (
                        <Checkbox
                            className={cn({ [styles.anchor]: original.user_id === multiSelectAnchorRowId })}
                            value={selectedRowIds.includes(original.user_id)}
                            onChange={(value, event) => {
                                if (event.shiftKey || event.ctrlKey || event.metaKey) {
                                    return;
                                }

                                onRowSelect(original);
                                if (!selectedRowIds.includes(original.user_id)) {
                                    setMultiSelectAnchorRowId(original.user_id);
                                }
                            }}
                        />
                    );
                },
            },
            {
                Header: 'Account',
                accessor: 'user_id',
                disableSortBy: true,
                Cell: ({
                    row: {
                        original: { user, userImage },
                    },
                }) => <TiktokUserBadge isLoading={props.loading} user={user} image={userImage} />,
            },
            {
                Header: 'Audio',
                accessor: 'audio_id',
                disableSortBy: true,
                Cell: ({
                    row: {
                        original: { audio },
                    },
                }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <>{audio?.title}</>;
                },
            },
            {
                Header: 'Deadline',
                accessor: 'deadline',
                disableSortBy: true,
                Cell: (cellProps) => (
                    <DeadlineCell {...cellProps} isLoading={props.loading} updatePostInvite={updatePostInvite} />
                ),
            },
            {
                Header: '# of chase emails',
                accessor: 'number_of_chase_emails_sent',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return <>{value}</>;
                },
            },
            {
                Header: 'Last contacted at',
                accessor: 'last_contacted_at',
                Cell: ({ value, row: { original } }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return (
                        <div className={styles.lastContactedAtContainer}>
                            {value ? formatDateTime(value) : '-'}
                            <Button className={styles.historyButton} onClick={() => onEmailHistoryClicked(original)}>
                                Email history
                                <TimerIcon className={styles.historyIcon} />
                            </Button>
                        </div>
                    );
                },
            },
            {
                Header: 'Payment Request',
                accessor: 'id',
                Cell: ({ value }) => {
                    if (props.loading) {
                        return <Skeleton />;
                    }

                    return (
                        <a
                            className={styles.paymentRequest}
                            href={`${MICROWAVE_APP_DOMAIN}/submit-post/${value}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Payment Request Form <LinkIcon className={styles.paymentRequestIcon} />
                        </a>
                    );
                },
            },
        ],
        [
            multiSelectAnchorRowId,
            onAllRowsSelect,
            onEmailHistoryClicked,
            onRowSelect,
            props.data,
            props.loading,
            selectedRowIds,
            updatePostInvite,
        ]
    );

    return (
        <PaginationTable
            tableClassName={styles.table}
            columns={columns}
            {...props}
            onContextMenu={(e) => {
                // MacOS ctrl+click is context menu action
                if (e.ctrlKey) {
                    e.preventDefault();
                }
            }}
            onRowClick={(row, page, event) => {
                if (event.shiftKey && !multiSelectAnchorRowId) {
                    onRowSelect(row);
                    setMultiSelectAnchorRowId(row.user_id);
                    return;
                }

                if (event.shiftKey) {
                    const anchorIndex = page.findIndex((row) => row.original.user_id === multiSelectAnchorRowId);
                    const targetIndex = page.findIndex((tableRow) => tableRow.original.user_id === row.user_id);

                    const rowsInRange = page.slice(
                        Math.min(anchorIndex, targetIndex),
                        Math.max(anchorIndex, targetIndex) + 1
                    );

                    rowsInRange.forEach((row) => {
                        if (!selectedRowIds.includes(row.original.user_id)) {
                            onRowSelect(row.original);
                        }
                    });
                    return;
                }

                if (event.ctrlKey || event.metaKey) {
                    onRowSelect(row);
                    setMultiSelectAnchorRowId(row.user_id);
                }
            }}
        />
    );
};

export default ContactedTable;
