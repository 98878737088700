import { CreatorsTableRow, Meta } from '../CreatorsTable';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import { CellContext } from '@tanstack/react-table';
import Rating from 'ui/Rating/Rating';
import RatingStarButton from 'ui-new/Rating/RatingStarButton/RatingStarButton';
import { useState } from 'react';
import { showNotification } from 'helpers';

type RatingData = CreatorsTableRow['rating'];
const getTableMeta = getTableMetaHelper<Meta>();

const RatingCell = <TRow extends CreatorsTableRow>({
    getValue,
    table,
    row: { original },
}: CellContext<TRow, RatingData>) => {
    const { isLoading, updateCreator } = getTableMeta(table);
    const rating = getValue();
    const [isUpdating, setIsUpdating] = useState(false);

    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <Rating
            disabled={isUpdating}
            rating={rating}
            maxRating={3}
            renderStarButton={RatingStarButton}
            onChange={(rating) => {
                setIsUpdating(true);
                updateCreator?.(original.id, { rating })
                    .then((response) => {
                        if (response.status === 200) {
                            showNotification('Rating updated', 'info');
                            return;
                        }

                        const error =
                            response.status === 404 ? 'Could not find creator' : response.data.rating?.toString();
                        showNotification(error || 'Could not update rating', 'error');
                    })
                    .catch(() => {
                        showNotification('Could not update rating', 'error');
                    })
                    .finally(() => {
                        setIsUpdating(false);
                    });
            }}
        />
    );
};

export default RatingCell;
