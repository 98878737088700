import { createSelectableContext } from 'Hooks/useNonNullContextSelector';
import { Actions, initialState, reducer, State } from './reducer';
import { Dispatch, ReactNode, useReducer } from 'react';

export const ReleaseContext = createSelectableContext<[State, Dispatch<Actions>] | null>(null);

type Props = { children?: ReactNode };
export const ReleaseProvider = ({ children }: Props) => {
    return <ReleaseContext.Provider value={useReducer(reducer, initialState)}>{children}</ReleaseContext.Provider>;
};
