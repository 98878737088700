import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReleaseContext } from '../../../ReleaseContext';
import { useCallback } from 'react';
import { microwave } from '@round/api';
import { useAbortableEffect } from '@round/utils';

export function useInstagramCampaignStats(instagramCampaignIds?: number[]) {
    const state = useNonNullContextSelector(ReleaseContext, ([values]) => values.instagram.campaignStats);
    const dispatch = useNonNullContextSelector(ReleaseContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (instagramCampaignIds: number[], requestInit?: RequestInit) => {
            if (!instagramCampaignIds.length) {
                return;
            }

            dispatch({ type: 'loadInstagramCampaignStats', payload: instagramCampaignIds });
            return Promise.all(
                instagramCampaignIds.map(async (campaignId) => {
                    try {
                        const response = await microwave.getInstagramCampaignStats(campaignId, requestInit);
                        if (response.status === 200) {
                            dispatch({
                                type: 'instagramCampaignStatsLoaded',
                                payload: { campaignId, stats: response.data },
                            });
                            return response;
                        }

                        dispatch({
                            type: 'errorLoadingInstagramCampaignStats',
                            payload: { campaignId, error: response.data.detail },
                        });
                        return response;
                    } catch (e) {
                        if (e instanceof Error && e.name === 'AbortError') {
                            throw e;
                        }

                        dispatch({
                            type: 'errorLoadingInstagramCampaignStats',
                            payload: { campaignId, error: 'Could not load instagram campaign stats' },
                        });

                        throw e;
                    }
                })
            );
        },
        [dispatch]
    );

    useAbortableEffect(
        (signal) => {
            if (!Array.isArray(instagramCampaignIds)) {
                return;
            }

            fetchData(instagramCampaignIds, { signal }).catch(() => {});
        },
        [instagramCampaignIds, fetchData]
    );

    const reset = useCallback(() => dispatch({ type: 'resetInstagramCampaignStats' }), [dispatch]);
    return {
        stats: state,
        fetchData,
        reset,
    };
}
