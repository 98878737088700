import { ApiResponse, ForbiddenResponse, NotFoundResponse } from '../../types';
import { Client } from './clients.types';
import { call } from '../../helpers';

type GetClientResponse = ApiResponse<Client, 200> | NotFoundResponse | ForbiddenResponse;

export async function getClient(clientId: number, requestInit?: RequestInit): Promise<GetClientResponse> {
    const response = await call(`/api/creatorbase/clients/${clientId}/`, requestInit);

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not retrieve client');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
