import { CellContext, RowData } from '@tanstack/react-table';
import { getTableMetaHelper } from '../helpers';
import Skeleton from '../../Indicators/Skeleton/Skeleton';

type Context<T extends RowData> = CellContext<T, number | string | null | undefined>;

type Meta = {
    isLoading?: boolean;
};

const getTableMeta = getTableMetaHelper<Meta>();

const SkeletonTableCell = <T extends RowData>({ table, getValue }: Context<T>) => {
    const { isLoading } = getTableMeta(table);
    if (isLoading) {
        return <Skeleton />;
    }

    return getValue();
};

export default SkeletonTableCell;
