import { ApiResponse, PaginatedApiResponseData, PaginatedRequest } from '../../types';
import { TiktokHashtag } from './hashtags.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';

export type GetTiktokHashtagsParams = Partial<
    PaginatedRequest & {
        search: string;
        id: string;
    }
>;

type GetTiktokHashtagsResponse = ApiResponse<PaginatedApiResponseData<TiktokHashtag>, 200>;

export async function getTiktokHashTags(
    params: GetTiktokHashtagsParams,
    requestInit?: RequestInit
): Promise<GetTiktokHashtagsResponse> {
    const response = await fetchWithToken(`/api/tiktok/hashtags/${encodeUrlSearchParams(params)}`, requestInit);

    if (!response.ok) {
        throw new Error('Could not get tiktok hashtags');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
