import { creatorbase, InstagramPost } from '@round/api';
import { PostIdData } from './types';
import { numberWithCommas } from 'helpers';
import moment from 'moment';
import { isNumber } from 'utility/utility';

export const getIdsFromPostIdData = (idData: PostIdData[]) => {
    return {
        postIds: idData.map(({ postId }) => postId),
        contentIds: idData.map(({ contentId }) => contentId),
    };
};

export const getPostContentId = (post: creatorbase.Post) => {
    if (post.platform === 'youtube') {
        return post.youtube_details?.content_id;
    }

    if (post.platform === 'tiktok') {
        return post.tiktok_details?.content_id;
    }

    if (post.platform === 'instagram') {
        return post.instagram_details?.content_id;
    }
};

export const formatPostStatsCellValue = (value: number | null | undefined) => {
    if (typeof value !== 'number') {
        return '-';
    }

    return numberWithCommas(value);
};

export const formatPostUploadDate = (date: string | Date) => moment(date).format('DD MMM YYYY');

export const getTikTokTotalEngagement = (post: creatorbase.TiktokPost) => {
    if (
        !post.tiktok_details ||
        (!isNumber(post.tiktok_details.like_count) &&
            !isNumber(post.tiktok_details.comment_count) &&
            !isNumber(post.tiktok_details.share_count) &&
            !isNumber(post.tiktok_details.save_count))
    ) {
        return null;
    }

    const { like_count, comment_count, share_count, save_count } = post.tiktok_details;
    return (like_count ?? 0) + (comment_count ?? 0) + (share_count ?? 0) + (save_count ?? 0);
};

export const getInstagramTotalEngagement = (post: InstagramPost | null | undefined) => {
    if (!post || (!isNumber(post.like_count) && !isNumber(post.comment_count))) {
        return null;
    }

    const { like_count, comment_count } = post;
    return (like_count ?? 0) + (comment_count ?? 0);
};

export const getYoutubeTotalEngagement = (post: creatorbase.YoutubePost) => {
    if (
        !post.youtube_details ||
        (!isNumber(post.youtube_details.like_count) && !isNumber(post.youtube_details.comment_count))
    ) {
        return null;
    }

    const { like_count, comment_count } = post.youtube_details;
    return (like_count ?? 0) + (comment_count ?? 0);
};
