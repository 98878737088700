import { CreatorsTableRow, Meta } from '../CreatorsTable';
import { CellContext } from '@tanstack/react-table';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import Select from 'ui-new/Select/Select';
import { countryOptions } from 'utility/constants';
import cn from 'classnames';
import { StylesConfig } from 'react-select';
import styles from './LocationCell.module.css';
import { showNotification } from 'helpers';
import { useState } from 'react';

type LocationData = string | null;
const getTableMeta = getTableMetaHelper<Meta>();

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        border: '1px solid transparent',
        padding: '0.5rem',
    }),
    menu: (base) => ({
        ...base,
        minWidth: 'max-content',
    }),
};

const Location = <T extends CreatorsTableRow>({ getValue, table, row: { original } }: CellContext<T, LocationData>) => {
    const { isLoading, updateCreator } = getTableMeta(table);
    const location = getValue();
    const value = countryOptions.find((option) => option.value === location) ?? null;
    const [isUpdating, setIsUpdating] = useState(false);

    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <Select
            isClearable
            isDisabled={isUpdating}
            placeholder="Search country"
            noOptionsMessage={() => 'No countries found'}
            styles={selectStyles}
            options={countryOptions}
            value={value}
            onChange={(option) => {
                setIsUpdating(true);
                updateCreator?.(original.id, { location: option?.value ?? null })
                    .then((response) => {
                        if (response.status === 200) {
                            showNotification('Location updated', 'info');
                            return;
                        }

                        const error =
                            response.status === 404 ? 'Could not find creator' : response.data.location?.toString();
                        showNotification(error || 'Could not update location', 'error');
                    })
                    .catch(() => {
                        showNotification('Could not update location', 'error');
                    })
                    .finally(() => {
                        setIsUpdating(false);
                    });
            }}
            formatOptionLabel={(option) => (
                <div className={styles.valueContainer}>
                    <span className={cn(`fi fi-${option.value.toLowerCase()}`, styles.flag)} />
                    <span className={styles.label}>{option.label}</span>
                </div>
            )}
        />
    );
};

export default Location;
