import { ContentTag, microwave } from '@round/api';
import { DataState } from '@round/utils';
import { ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { Context } from '@fluentui/react-context-selector';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';

type State = DataState<ContentTag[]>;
type Actions =
    | ReducerAction<'loadContentTags'>
    | ReducerActionWithPayload<'contentTagsLoaded', ContentTag[]>
    | ReducerActionWithPayload<'errorLoadingContentTags', string>;

export const initialState: State = {
    status: 'idle',
    data: null,
    error: null,
};

export const reducer = createReducer<State, Actions>({
    loadContentTags: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    contentTagsLoaded: (state, { payload }) => ({
        status: 'success',
        error: null,
        data: state.data?.concat(payload) ?? payload,
    }),
    errorLoadingContentTags: (state, { payload }) => ({
        ...state,
        error: payload,
        status: 'error',
    }),
});

export default function makeMicrowaveContentTagsDataHook<TValue extends BaseDataHookContextValue<Actions>>(
    context: Context<TValue>,
    selector: ContextSelector<TValue, State>
) {
    return function useMicrowaveContentTags() {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (requestInit?: RequestInit) => {
                try {
                    dispatch({ type: 'loadContentTags' });
                    const tags = await microwave.getAllContentTags({}, requestInit);
                    dispatch({ type: 'contentTagsLoaded', payload: tags });
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({ type: 'errorLoadingContentTags', payload: 'Could not load content tags' });
                    throw e;
                }
            },
            [dispatch]
        );

        return { ...state, fetchData };
    };
}
