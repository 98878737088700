import { InstagramUserImage } from '@round/api';
import { SkeletonTableCell } from '@round/ui-kit';
import { ColumnDef } from '@tanstack/react-table';
import { formatNumberToKNotation } from '@round/utils';
import AccountCell, { AccountData } from '../../CreatorsTable/AccountCell/AccountCell';
import { buildInstagramUserUrl } from 'helpers';
import CreatorsTable, { CreatorsTableRow, Props as CreatorsTableProps } from '../../CreatorsTable/CreatorsTable';
import LocationCell from '../../CreatorsTable/LocationCell/LocationCell';
import TagsCell from '../../CreatorsTable/TagsCell/TagsCell';
import RatingCell from '../../CreatorsTable/RatingCell/RatingCell';

export type InstagramCreatorsTableRow = CreatorsTableRow & {
    instagramUserImage: InstagramUserImage | null;
};

type Props = Omit<CreatorsTableProps<InstagramCreatorsTableRow>, 'columns'>;

const InstagramCreatorsTable = (props: Props) => {
    const columns: ColumnDef<InstagramCreatorsTableRow, any>[] = [
        {
            id: 'account',
            header: 'Account',
            accessorFn: ({ instagramUserImage, instagram_user_username }): AccountData => ({
                image:
                    instagramUserImage?.avatar_thumb.cached_url || instagramUserImage?.avatar_thumb.original_url || '',
                username: instagram_user_username || '',
                title: '',
                profileUrl: buildInstagramUserUrl(instagram_user_username || ''),
            }),
            cell: AccountCell,
        },
        {
            id: 'followerCount',
            header: 'Follower count',
            accessorFn: (row) => formatNumberToKNotation(row.follower_count),
            cell: SkeletonTableCell,
        },
        {
            accessorKey: 'location',
            header: 'Location',
            cell: LocationCell,
        },
        {
            accessorKey: 'contentTagsOptions',
            header: 'Tags',
            cell: TagsCell,
        },
        {
            accessorKey: 'rating',
            header: 'Rating',
            cell: RatingCell,
        },
        {
            accessorKey: 'email',
            header: 'Email',
            cell: SkeletonTableCell,
        },
    ];

    return <CreatorsTable columns={columns} {...props} />;
};

export default InstagramCreatorsTable;
