import { useAbortableEffect } from '@round/utils';
import { microwave } from '@round/api';
import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReleaseContext } from '../ReleaseContext';
import { useCallback } from 'react';

export default function useRelease(releaseId?: number | undefined) {
    const state = useNonNullContextSelector(ReleaseContext, ([values]) => values.release);
    const dispatch = useNonNullContextSelector(ReleaseContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (releaseId: number, requestInit?: RequestInit) => {
            dispatch({ type: 'loadRelease' });

            try {
                const response = await microwave.getRelease(releaseId, requestInit);
                if (response.status === 404) {
                    dispatch({ type: 'errorLoadingRelease', payload: 'Release not found' });
                    return response;
                }

                dispatch({ type: 'releaseInitialized', payload: response.data });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({ type: 'errorLoadingRelease', payload: 'Error loading release' });
                throw e;
            }
        },
        [dispatch]
    );

    const isReleaseInitialized = state.status === 'success' || state.status === 'error';
    useAbortableEffect(
        (signal) => {
            if (releaseId === undefined || isReleaseInitialized) {
                return;
            }

            fetchData(releaseId, { signal }).catch(() => {});
        },
        [fetchData, isReleaseInitialized, releaseId]
    );

    const reset = useCallback(() => dispatch({ type: 'resetRelease' }), [dispatch]);

    return {
        ...state,
        fetchData,
        reset,
    };
}
