import { AppServer, RouteHandlerContext } from '../mirage';
import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { Creator, CreatorList } from './creatorbase.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../mirage.helpers';
import * as projectsMirage from './projects/projects.mirage';
import * as brandsMirage from './brands/brands.mirage';
import * as teamsMirage from './teams/teams.mirage';
import * as campaignsMirage from './campaigns/campaigns.mirage';
import * as usersMirage from './users/users.mirage';
import * as aggregatedPostStatsMirage from './aggregatedPostStats/aggregatedPostStats.mirage';
import * as aggregatedAudioStatsMirage from './aggregatedAudioStats/aggregatedAudioStats.mirage';
import * as postsMirage from './posts/posts.mirage';
import * as songsMirage from './songs/songs.mirage';
import * as timeSeriesMirage from './timeSeries/timeSeries.mirage';
import * as tiktokAudiosMirage from './tiktokAudios/tiktokAudios.mirage';
import * as postStatsMirage from './postStats/postStats.mirage';
import * as reportsMirage from './reports/reports.mirage';
import * as publicReportMirage from './publicReport/publicReport.mirage';
import * as clientsMirage from './clients/clients.mirage';

export const models = {
    creatorbaseCreator: Model as ModelDefinition<Creator>,
    creatorbaseCreatorList: Model as ModelDefinition<CreatorList>,

    ...projectsMirage.models,
    ...brandsMirage.models,
    ...teamsMirage.models,
    ...campaignsMirage.models,
    ...usersMirage.models,
    ...aggregatedAudioStatsMirage.models,
    ...aggregatedPostStatsMirage.models,
    ...postsMirage.models,
    ...songsMirage.models,
    ...timeSeriesMirage.models,
    ...tiktokAudiosMirage.models,
    ...postStatsMirage.models,
    ...reportsMirage.models,
    ...publicReportMirage.models,
    ...clientsMirage.models,
};

export const serializers = {
    creatorbaseCreator: makeSerializer<Creator>([]),
    creatorbaseCreatorList: makeSerializer<CreatorList>([]),

    ...projectsMirage.serializers,
    ...brandsMirage.serializers,
    ...teamsMirage.serializers,
    ...campaignsMirage.serializers,
    ...usersMirage.serializers,
    ...aggregatedAudioStatsMirage.serializers,
    ...aggregatedPostStatsMirage.serializers,
    ...postsMirage.serializers,
    ...songsMirage.serializers,
    ...timeSeriesMirage.serializer,
    ...tiktokAudiosMirage.serializers,
    ...postStatsMirage.serializers,
    ...reportsMirage.serializers,
    ...publicReportMirage.serializers,
    ...clientsMirage.serializers,
};

export const factories = {
    creatorbaseCreator: createFactory<Creator>({
        avatar_thumb: '',
        follower_count(index: number) {
            return index;
        },
        username(index: number) {
            return `username ${index}`;
        },
        cost_amount: '0.000',
        cost_currency_symbol: '$',
        country: 'UK',
        is_accepted: null,
    }),

    ...projectsMirage.factories,
    ...brandsMirage.factories,
    ...teamsMirage.factories,
    ...campaignsMirage.factories,
    ...usersMirage.factories,
    ...aggregatedAudioStatsMirage.factories,
    ...aggregatedPostStatsMirage.factories,
    ...postsMirage.factories,
    ...songsMirage.factories,
    ...timeSeriesMirage.factories,
    ...tiktokAudiosMirage.factories,
    ...postStatsMirage.factories,
    ...reportsMirage.factories,
    ...publicReportMirage.factories,
    ...clientsMirage.factories,
};

export function handleRequests(server: AppServer) {
    const creatorListEndpoint = '/api/creatorbase/creator-list/:id/';
    server.get(creatorListEndpoint, function (this: RouteHandlerContext, schema, request) {
        const list = schema.find('creatorbaseCreatorList', request.params.id);
        if (!list) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        return list;
    });

    server.get(`${creatorListEndpoint}tiktok-creators/`, function (this: RouteHandlerContext, schema, request) {
        const creators = schema.all('creatorbaseCreator');
        return buildPaginatedResponse(creators, {
            url: `${creatorListEndpoint}tiktok-creators/`,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'creatorbaseCreator'),
        });
    });

    server.patch(`${creatorListEndpoint}tiktok-creators/:id/`, function (this: RouteHandlerContext, schema, request) {
        const creator = schema.find('creatorbaseCreator', request.params.id);
        if (!creator) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        creator.update(JSON.parse(request.requestBody));
        return creator;
    });

    projectsMirage.handleRequests(server);
    brandsMirage.handleRequests(server);
    teamsMirage.handleRequests(server);
    campaignsMirage.handleRequests(server);
    usersMirage.handleRequests(server);
    aggregatedAudioStatsMirage.handleRequests(server);
    aggregatedPostStatsMirage.handleRequests(server);
    postsMirage.handleRequests(server);
    songsMirage.handleRequests(server);
    timeSeriesMirage.handleRequests(server);
    tiktokAudiosMirage.handleRequests(server);
    postStatsMirage.handleRequests(server);
    reportsMirage.handleRequests(server);
    publicReportMirage.handleRequests(server);
    clientsMirage.handleRequests(server);
}
