import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react';
import cn from 'classnames';
import styles from './Button.module.css';
import { ReactComponent as SpinnerIcon } from './Spinner.svg';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    appearance: 'primary' | 'contrast' | 'outlined' | 'ghost';
    isLoading?: boolean;
    rightIcon?: SVGSVGElement | React.ReactElement;
    leftIcon?: SVGSVGElement | React.ReactElement;
};

const Button = forwardRef<HTMLButtonElement, Props>(({ appearance, rightIcon, isLoading, ...props }, ref) => {
    return (
        <button {...props} ref={ref} className={cn(styles.button, styles[appearance], props.className)}>
            <>
                {props.leftIcon}
                <>{props.children}</>
                {isLoading ? <SpinnerIcon className={styles.spinner} /> : rightIcon}
            </>
        </button>
    );
});

export default Button;
