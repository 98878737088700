import { call, encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { User } from './users.types';

export type GetUsersParams = Partial<
    PaginatedRequest & {
        id: string;
        team_id: number;
        assigned_to_campaign_for_project_id: number;
        assigned_to_campaign_id: number;
    }
>;
type GetUsersResponse = ApiResponse<PaginatedApiResponseData<User>, 200> | ForbiddenResponse | NotFoundResponse;

export async function getUsers(params: GetUsersParams, requestInit?: RequestInit): Promise<GetUsersResponse> {
    const response = await fetchWithToken(`/api/creatorbase/users/${encodeUrlSearchParams(params)}`, requestInit);

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get users');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetUserResponse = ApiResponse<User, 200> | ForbiddenResponse | NotFoundResponse;

export async function getUser(id: number, requestInit?: RequestInit): Promise<GetUserResponse> {
    const response = await call(`/api/creatorbase/users/${id}/`, requestInit);
    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get user');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
