import React, { useCallback, useMemo, useState } from 'react';
import TiktokInfluencersTable from './components/TiktokInfluencersTable/TiktokInfluencersTable';
import useUrlState from '../../../../Hooks/useUrlState';
import styles from './TiktokInfluencerExplore.module.css';
import { usePaginatedFetch } from '../../../../Hooks/usePaginatedFetch';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import SearchInput from '../../../../ui/SearchInput/SearchInput';
import Button from '../../../../ui/Buttons/Button/Button';
import { ReactComponent as FilterSearchIcon } from '../../../../assets/FilterSearch.svg';
import Label from '../../../../ui/DataEntry/Label/Label';
import cn from 'classnames';
import {
    getTiktokInfluencerUserStatsList,
    TiktokUserImage,
    getTiktokUserImages,
    InfluencerUserCampaigns,
    getInfluencerUserInfluencerPlans,
} from '@round/api';
import { DebounceInput } from 'react-debounce-input';
import { ProtectedByUserGroups } from '../../../../SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import AddInfluencerModal from './AddInfluencerModal/AddInfluencerModal';
import TiktokInfluencerUserModal from './TiktokInfluencerUserModal/TiktokInfluencerUserModal';
import { TiktokUserDataProvider } from './TiktokInfluencerUserModal/TiktokUserDataContext/TiktokUserDataContext';
import { OrderByParam } from '../../../../Hooks/useReactTableSortToOrderBy';
import useNonNullContext from '../../../../Hooks/useNonNullContext';
import { OptionsContext } from '../../../../contexts/OptionsContext/OptionsContext';
import { TiktokInfluencerTableRow } from './components/TiktokInfluencersTable/TiktokInfluencersTable.types';
import { AddInfluencerToCampaignOptionsProvider } from '../../../Influencer/containers/AddInfluencerToCampaign/AddInfluencerToCampaignContext';
import { mapContentTagsToOptions, mapInfluencerTagsToOptions } from '../../../../helpers';
import { StylesConfig, ValueType } from 'react-select';
import { useInfluencerTags } from '../../../../Hooks/useInfluencerTags';
import { Checkbox, ControlWithSearchIcon } from '@round/ui-kit';
import { countryOptions } from '../../../../utility/constants';
import { GenericDropdownOption } from '../../../../App.types';
import Select from '../../../../ui/DataEntry/Select/Select';
import CategoriesFilter from '../../../Tags/components/Filters/CategoriesFilter/CategoriesFilter';
import {
    mapConditionsToApi,
    parseConditionsFromUrlString,
    stringifyConditionsToUrlString,
} from '../../../Tags/components/Filters/helpers';
import { useContentTags } from 'contexts/ContentTagsContext';
import useInfluencerPlans from 'Modules/Advertising/InfluencerPlan/hooks/useInfluencerPlans';
import uniq from 'lodash/uniq';
import { useAudioSelect } from 'Modules/TikTok/hooks/useAudioSelect';
import GroupedOptionMultiSelect from 'ui/DataEntry/Select/GroupedSelectionsMultiSelect/GroupedSelectionsMultiSelect';
import { AudioSelectOption } from 'Modules/TikTok/components/AudioSelectComponents/AudioSelectComponents';
import { AudioPlayerProvider } from 'Modules/AudioPlayer/AudioPlayerContext';

type UrlState = Partial<{
    page: number;
    pageSize: number;
    minFollowers: number | string;
    maxFollowers: number | string;
    minAvgViews: number | string;
    maxAvgViews: number | string;
    minCost: number | string;
    maxCost: number | string;
    locations: string;
    search: string;
    order_by_asc: string | undefined;
    order_by_desc: string | undefined;
    only_apex: string | undefined;
    content_tags: string | undefined;
    pastAudios: string | undefined;
}>;

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        borderColor: '#C2CFE0',
        fontSize: '0.75rem',
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: '0.75rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }),
    dropdownIndicator: () => ({
        display: 'none',
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '0 0.5rem',
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: '0 0.5rem',
    }),
    multiValue: (base) => ({
        ...base,
        padding: '0',
    }),
    menu: (base) => ({
        ...base,
        minWidth: '15rem',
        width: 'max-content',
        maxWidth: '30rem',
    }),
    menuList: (base) => ({
        ...base,
        minWidth: 'max-content',
        height: 'auto',
        maxHeight: '30rem',
    }),
};

const DEBOUNCE_TIMEOUT = 700;

const TiktokInfluencerExplore = () => {
    const { currencies } = useNonNullContext(OptionsContext);
    const [addInfluencerModalOpen, setAddInfluencerModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<TiktokInfluencerTableRow | null>(null);
    const [tiktokInfluencerUserModalOpen, setTiktokInfluencerUserModalOpen] = useState(false);

    const [showFilters, setShowFilters] = useState(false);
    const [urlState, setUrlState] = useUrlState<UrlState>(
        {
            page: 1,
            pageSize: 25,
            order_by_desc: 'created',
            locations: '',
        },
        {
            shouldSetMissingInitialValues: true,
        }
    );
    const { influencerTags, tagsLoading } = useInfluencerTags();
    const { locationTagOptions } = useMemo(() => mapInfluencerTagsToOptions(influencerTags), [influencerTags]);

    const { tags: contentTags, isInitialized: areContentTagsInitialized, init: initContentTags } = useContentTags();

    useAbortableEffect(
        (signal) => {
            if (!areContentTagsInitialized) {
                initContentTags({ signal });
            }
        },
        [areContentTagsInitialized, initContentTags]
    );

    const page = Number(urlState.page);
    const setPage = (page: number) => setUrlState({ page });
    const pageSize = Number(urlState.pageSize);
    const setPageSize = (pageSize: number | undefined) => setUrlState({ pageSize });
    const search = urlState.search;
    const setSearch = (search: string) => setUrlState({ search, page: 1 });
    const apexOnlyFilterActive = urlState.only_apex === 'true';
    const setApexOnlyFilterActive = (isActive: boolean) => setUrlState({ only_apex: isActive.toString(), page: 1 });

    const selectedCountryOptions = useMemo(() => {
        const locationCodes = urlState.locations?.split(',') ?? [];
        return countryOptions.filter((option) => locationCodes.includes(option.value));
    }, [urlState.locations]);

    const setSelectedCountryOptions = useCallback(
        (selectedOptions: ValueType<GenericDropdownOption<string>, true>) => {
            setUrlState({ locations: selectedOptions?.map((opt) => opt.value).join(','), page: 1 });
        },
        [setUrlState]
    );

    const minFollowers = urlState.minFollowers ? Number(urlState.minFollowers) : undefined;
    const setMinFollowers = (minFollowers: number | string) => setUrlState({ minFollowers, page: 1 });
    const maxFollowers = urlState.maxFollowers ? Number(urlState.maxFollowers) : undefined;
    const setMaxFollowers = (maxFollowers: number | string) => setUrlState({ maxFollowers, page: 1 });

    const minAvgViews = urlState.minAvgViews ? Number(urlState.minAvgViews) : undefined;
    const setMinAvgViews = (minAvgViews: number | string) => setUrlState({ minAvgViews, page: 1 });
    const maxAvgViews = urlState.maxAvgViews ? Number(urlState.maxAvgViews) : undefined;
    const setMaxAvgViews = (maxAvgViews: number | string) => setUrlState({ maxAvgViews, page: 1 });

    const minCost = urlState.minCost ? Number(urlState.minCost) : undefined;
    const setMinCost = (minCost: number | string) => setUrlState({ minCost, page: 1 });
    const maxCost = urlState.maxCost ? Number(urlState.maxCost) : undefined;
    const setMaxCost = (maxCost: number | string) => setUrlState({ maxCost, page: 1 });
    const orderBy: OrderByParam<TiktokInfluencerTableRow> = useMemo(
        () => ({
            order_by_desc: urlState.order_by_desc as keyof TiktokInfluencerTableRow,
            order_by_asc: urlState.order_by_asc as keyof TiktokInfluencerTableRow,
        }),
        [urlState.order_by_asc, urlState.order_by_desc]
    );

    const setOrderBy = useCallback(
        (params: OrderByParam<TiktokInfluencerTableRow>) =>
            setUrlState({
                order_by_desc: params.order_by_desc || undefined,
                order_by_asc: params.order_by_asc || undefined,
            }),
        [setUrlState]
    );

    const { ...audioSelectProps } = useAudioSelect({
        initialAudioIdData: urlState.pastAudios?.split(',').map((id) => Number(id)) ?? [],
        isMulti: true,
    });

    const audioSelectValueLabel = audioSelectProps.value?.length
        ? `${audioSelectProps.value.length} past audio${audioSelectProps.value.length > 1 ? 's' : ''} selected`
        : '';

    const [tableInitialized, setTableInitialized] = useState(false);
    const setTableNotInitialized = useCallback(() => {
        setTableInitialized(false);
    }, []);

    const { page: dataPage, count, loading, refresh } = usePaginatedFetch(
        getTiktokInfluencerUserStatsList,
        {
            page,
            search,
            page_size: pageSize,
            min_follower_count: minFollowers,
            max_follower_count: maxFollowers,
            min_avg_video_plays_per_post: minAvgViews,
            max_avg_video_plays_per_post: maxAvgViews,
            min_client_cost: minCost,
            max_client_cost: maxCost,
            location: selectedCountryOptions.map((t) => t.value).join(','),
            only_apex: apexOnlyFilterActive || undefined,
            ...orderBy,
            content_tags: mapConditionsToApi(parseConditionsFromUrlString(urlState.content_tags)),
            audio: urlState.pastAudios,
        },
        {
            beforeFetch: setTableNotInitialized,
        }
    );

    const [images, setImages] = useState<TiktokUserImage[]>([]);
    const [imagesLoading, setImagesLoading] = useState(false);
    const [influencerUserCampaigns, setInfluencerUserCampaigns] = useState<InfluencerUserCampaigns[]>([]);
    const [influencerUserCampaignsLoading, setInfluencerUserCampaignsLoading] = useState(false);
    const tableLoading = loading || imagesLoading || influencerUserCampaignsLoading || !tableInitialized;
    const { fetchData: fetchInfluencerPlans, ...influencerPlansData } = useInfluencerPlans();

    useAbortableEffect(
        (signal) => {
            const plansToFetch = uniq(
                influencerUserCampaigns.map((campaign) => campaign.plans.map((p) => p.plan_id)).flat()
            );

            if (!plansToFetch.length || influencerPlansData.status === 'initialized' || tableLoading) {
                return;
            }

            fetchInfluencerPlans(
                { id: plansToFetch.toString(), page_size: plansToFetch.length },
                { signal }
            ).catch(() => {});
        },
        [fetchInfluencerPlans, influencerPlansData.status, influencerUserCampaigns, tableLoading]
    );

    const rows: TiktokInfluencerTableRow[] = useMemo(
        () =>
            dataPage.map((stats) => {
                const image = images.find((image) => image.user_id === stats.user_id);
                const campaigns = influencerUserCampaigns.find((c) => c.influencer_id === stats.influencer_user_id);
                const plans =
                    influencerPlansData.data?.results.filter(
                        (plan) => !!campaigns?.plans.find((p) => p.plan_id === plan.id)
                    ) ?? [];

                return {
                    ...stats,
                    image: image?.avatar_thumb.cached_url ?? image?.avatar_thumb.original_url,
                    campaigns,
                    plans,
                };
            }),
        [dataPage, images, influencerPlansData.data?.results, influencerUserCampaigns]
    );

    const fetchImages = useCallback(async (ids: number[], requestInit?: RequestInit) => {
        if (!ids.length) {
            return;
        }

        try {
            setImagesLoading(true);
            const images = await getTiktokUserImages(ids, requestInit);
            setImages(images);
        } catch {
            // no-op
        } finally {
            setImagesLoading(false);
        }
    }, []);

    const fetchCampaigns = useCallback(async (influencerUserIds: number[], requestInit?: RequestInit) => {
        if (!influencerUserIds.length) {
            return;
        }

        try {
            setInfluencerUserCampaignsLoading(true);
            const response = await getInfluencerUserInfluencerPlans(
                { tiktok_influencer_users: influencerUserIds.join() },
                requestInit
            );
            if (response.status === 200) {
                setInfluencerUserCampaigns(response.data);
                return;
            }
        } catch {
            // no-op
        } finally {
            setInfluencerUserCampaignsLoading(false);
        }
    }, []);

    const refreshCampaignsForInfluencerUser = useCallback(
        async (influencerUserId: number) => {
            try {
                const response = await getInfluencerUserInfluencerPlans({
                    tiktok_influencer_users: influencerUserId.toString(),
                });
                if (response.status === 200) {
                    setInfluencerUserCampaigns((campaigns) =>
                        campaigns.map((c) => (c.influencer_id === influencerUserId ? response.data[0] : c))
                    );

                    influencerPlansData.reset();
                }
            } catch {
                // no-op
            }
        },
        [influencerPlansData]
    );

    useAbortableEffect(
        (signal) => {
            Promise.all([
                fetchImages(
                    dataPage.map((stats) => stats.user_id).filter((id): id is number => !!id),
                    { signal }
                ),
                fetchCampaigns(
                    dataPage.map((stats) => stats.influencer_user_id),
                    { signal }
                ),
            ]).finally(() => setTableInitialized(true));
        },
        [dataPage, fetchImages, fetchCampaigns]
    );

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Tiktok influencer explore</h1>

            <div className={styles.row}>
                <SearchInput
                    className={styles.search}
                    value={search}
                    onChange={(search) => {
                        setSearch(search);
                        influencerPlansData.reset();
                    }}
                    debounceTimout={DEBOUNCE_TIMEOUT}
                />

                <div className={styles.buttonsContainer}>
                    <Button
                        className={styles.showFiltersButton}
                        type="bordered"
                        onClick={() => setShowFilters((show) => !show)}
                    >
                        <FilterSearchIcon className={styles.showFiltersIcon} />
                        Filters
                    </Button>
                    <ProtectedByUserGroups groups={['explorer_tiktok_editor', 'influencer_editor']}>
                        <Button type="filled" color="black" onClick={() => setAddInfluencerModalOpen(true)}>
                            Add influencer
                        </Button>

                        <AddInfluencerModal
                            contentTagOptions={mapContentTagsToOptions(contentTags)}
                            locationTagOptions={locationTagOptions}
                            currencies={currencies}
                            isModalOpen={addInfluencerModalOpen}
                            closeModal={() => setAddInfluencerModalOpen(false)}
                        />
                    </ProtectedByUserGroups>
                </div>
            </div>

            {showFilters && (
                <div className={styles.filtersRow}>
                    <div className={cn(styles.filtersGroup, styles.tagsFilters)}>
                        <CategoriesFilter
                            className={styles.categoriesFilter}
                            conditions={parseConditionsFromUrlString(urlState.content_tags)}
                            onChange={(conditions) => {
                                setUrlState({ content_tags: stringifyConditionsToUrlString(conditions) });
                                influencerPlansData.reset();
                            }}
                            options={mapContentTagsToOptions(contentTags)}
                            optionsLoading={tagsLoading}
                        />

                        <Select
                            isMulti
                            value={selectedCountryOptions}
                            onChange={(val) => {
                                setSelectedCountryOptions(val);
                                influencerPlansData.reset();
                            }}
                            className={styles.locationsFilter}
                            placeholder="Filter by location"
                            options={countryOptions}
                            styles={selectStyles}
                            components={{ Control: ControlWithSearchIcon }}
                        />

                        <AudioPlayerProvider>
                            <GroupedOptionMultiSelect
                                {...audioSelectProps}
                                onChange={(audios) => {
                                    setUrlState({ pastAudios: audios?.map((audio) => audio.value).join(',') });
                                    audioSelectProps.onChange(audios);
                                    influencerPlansData.reset();
                                }}
                                styles={selectStyles}
                                placeholder="Filter by audios used"
                                components={{ Control: ControlWithSearchIcon, Option: AudioSelectOption }}
                                isMulti
                                filterOption={null}
                                valueLabel={audioSelectValueLabel}
                            />
                        </AudioPlayerProvider>
                    </div>

                    <div className={styles.filtersGroup}>
                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="minFollowers">
                                Min Followers
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                type="number"
                                id="minFollowers"
                                value={minFollowers}
                                onChange={(e) => {
                                    setMinFollowers(e.target.value);
                                    influencerPlansData.reset();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>

                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="maxFollowers">
                                Max Followers
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                type="number"
                                id="maxFollowers"
                                value={maxFollowers}
                                onChange={(e) => {
                                    setMaxFollowers(e.target.value);
                                    influencerPlansData.reset();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>
                    </div>

                    <div className={styles.filtersGroup}>
                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="minAvgViews">
                                Min Avg Views
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                type="number"
                                id="minAvgViews"
                                value={minAvgViews}
                                onChange={(e) => {
                                    setMinAvgViews(e.target.value);
                                    influencerPlansData.reset();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>

                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="maxAvgViews">
                                Max Avg Views
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                type="number"
                                id="maxAvgViews"
                                value={maxAvgViews}
                                onChange={(e) => {
                                    setMaxAvgViews(e.target.value);
                                    influencerPlansData.reset();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>
                    </div>

                    <div className={styles.filtersGroup}>
                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="minCost">
                                Min Cost
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                type="number"
                                id="minCost"
                                value={minCost}
                                onChange={(e) => {
                                    setMinCost(e.target.value);
                                    influencerPlansData.reset();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>

                        <div className={styles.filter}>
                            <Label className={styles.filterLabel} htmlFor="maxCost">
                                Max Cost
                            </Label>
                            <DebounceInput
                                className={styles.filterInput}
                                type="number"
                                id="maxCost"
                                value={maxCost}
                                onChange={(e) => {
                                    setMaxCost(e.target.value);
                                    influencerPlansData.reset();
                                }}
                                debounceTimeout={DEBOUNCE_TIMEOUT}
                            />
                        </div>
                        <div className={styles.apexOnlyFilterWrapper}>
                            <Label className={styles.filterLabel} htmlFor="apexOnly">
                                Apex Only
                            </Label>
                            <Checkbox
                                value={apexOnlyFilterActive}
                                onChange={(value) => {
                                    setApexOnlyFilterActive(value);
                                    influencerPlansData.reset();
                                }}
                                className={styles.apexOnlyFilter}
                                id="apexOnly"
                            />
                        </div>
                    </div>
                </div>
            )}
            <AddInfluencerToCampaignOptionsProvider>
                <TiktokInfluencersTable
                    loading={tableLoading}
                    data={rows}
                    count={count}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                        setPage(page);
                        influencerPlansData.reset();
                    }}
                    onPageSizeChange={(pageSize) => {
                        setPageSize(pageSize);
                        influencerPlansData.reset();
                    }}
                    orderBy={orderBy}
                    onOrderByChange={setOrderBy}
                    onRowClick={(row) => {
                        setSelectedRow(row);
                        setTiktokInfluencerUserModalOpen(true);
                    }}
                    onInfluencerAddedToCampaign={refreshCampaignsForInfluencerUser}
                />

                <TiktokUserDataProvider>
                    <TiktokInfluencerUserModal
                        isModalOpen={tiktokInfluencerUserModalOpen}
                        closeModal={(influencerUser) => {
                            const tagsAdded =
                                influencerUser?.content_tags &&
                                !influencerUser?.content_tags.every((tag) => selectedRow?.content_tags.includes(tag));

                            const tagsRemoved = !selectedRow?.content_tags.every((tag) =>
                                influencerUser?.content_tags.includes(tag)
                            );
                            if (tagsAdded || tagsRemoved) {
                                refresh();
                            }

                            setTiktokInfluencerUserModalOpen(false);
                            setSelectedRow(null);
                        }}
                        influencerUserStats={selectedRow}
                        influencerUserId={selectedRow?.influencer_user_id}
                        plans={selectedRow?.plans}
                    />
                </TiktokUserDataProvider>
            </AddInfluencerToCampaignOptionsProvider>
        </div>
    );
};

export default TiktokInfluencerExplore;
