import React from 'react';
import { Props as SelectProps, ValueType } from 'react-select';
import { Select, SelectedCountValueContainer } from '@round/ui-kit';
import { GenericDropdownOption } from '../../App.types';
import range from 'lodash/range';
import RatingSelectOption from './RatingSelectOption';

type Props = Omit<SelectProps<GenericDropdownOption<number>, true>, 'options' | 'value' | 'onChange' | 'isMulti'> & {
    maxRating: number;
    value: number[] | undefined;
    onChange: (rating: number[] | undefined) => void;
};

export const getRatingOptions = (maxRating: number) =>
    range(1, maxRating + 1).map((rating) => ({
        value: rating,
        label: String(rating),
    }));

const RatingSelect = ({ maxRating, value, onChange, isMulti = true, ...props }: Props) => {
    const options = getRatingOptions(maxRating);

    return (
        <Select
            {...props}
            isMulti
            value={options.filter((o) => value?.includes(o.value)) as ValueType<GenericDropdownOption<number>, true>}
            onChange={(options) => {
                onChange(options?.map((o) => o.value));
            }}
            options={options}
            components={{ ValueContainer: SelectedCountValueContainer, Option: RatingSelectOption }}
        />
    );
};

export default RatingSelect;
