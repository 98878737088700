import { useAbortableEffect, useDataState } from '@round/utils';
import { creatorbase } from '@round/api';
import { useCallback } from 'react';

export default function useUser(userId?: number | null) {
    const [state, setState] = useDataState<creatorbase.User>({ status: 'idle', data: null, error: null });

    const fetchData = useCallback(
        async (userId: number, requestInit?: RequestInit) => {
            try {
                setState('loading');
                const response = await creatorbase.getUser(userId, requestInit);
                if (response.status === 200) {
                    setState('success', { data: response.data });
                    return response;
                }

                const message = response.data.detail;
                setState('error', { error: message });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                setState('error', { error: 'Could not get user' });
                throw e;
            }
        },
        [setState]
    );

    useAbortableEffect(
        (signal) => {
            if (typeof userId !== 'number') {
                return;
            }

            fetchData(userId, { signal }).catch(() => {});
        },
        [fetchData, userId]
    );

    return {
        ...state,
        fetchData,
    };
}
