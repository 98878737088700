import useOnClickOutside from 'Hooks/useOnClickOutside';
import { Fragment, useContext, useState } from 'react';
import styles from './MobileNavbar.module.css';
import { NavElement, isNavItemGroup } from 'Modules/Navigation/whitelabel/routes';
import { ReactComponent as MenuIcon } from 'assets/Menu.svg';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as RoundLogoShort } from 'assets/brand/LogoShort.svg';
import { ReactComponent as LogoutIcon } from 'assets/Logout.svg';
import { AuthContext } from 'Modules/Auth/contexts/AuthContext';
import { ReactComponent as ArrowIcon } from 'assets/ChevronRight.svg';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';
import useCurrentUserClients from 'Modules/Plans/Clients/hooks/useCurrentUserClients';
import { Image } from '@round/ui-kit';

const NavMenu = ({ items }: { items: NavElement[] }) => {
    const authContext = useContext(AuthContext);
    const location = useLocation();
    const isCurrentPath = (path: string) => location.pathname.includes(path);

    const shouldShowRoundLink = useCheckUserGroupsAccess(['round_product']);

    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [openNavGroup, setOpenNavGroup] = useState<number | null>(null);
    const handleSetOpenNavGroup = (index: number | null) => {
        if (openNavGroup === index) {
            setOpenNavGroup(null);
        } else {
            setOpenNavGroup(index);
        }
    };

    useOnClickOutside(ref, () => setIsMenuOpen(false));

    const { clients } = useCurrentUserClients();
    const firstClient = clients?.[0];

    return (
        <>
            <div ref={setRef} className={styles.menuWrapper}>
                <MenuIcon
                    className={cn(styles.navMenuIcon, { [styles.menuIsOpen]: isMenuOpen })}
                    onClick={() => {
                        setIsMenuOpen((prev) => !prev);
                        handleSetOpenNavGroup(null);
                    }}
                />
                <div className={styles.logoContainer}>
                    <RoundLogoShort className={styles.roundLogo} />
                    <hr />
                    <Image src={firstClient?.logo || ''} className={styles.sonyLogo} />
                </div>

                <LogoutIcon
                    className={styles.logout}
                    onClick={() => {
                        authContext?.logout?.();
                    }}
                />

                <div className={cn(styles.menuDropdownList, { [styles.isNavGroupOpen]: isMenuOpen })}>
                    {items.map((item, index) => {
                        if (isNavItemGroup(item)) {
                            const isNavGroupOpen = openNavGroup === index;

                            return (
                                <Fragment key={item.title}>
                                    <div
                                        className={cn(styles.navItem, {
                                            [styles.activeRouteNavItem]: isNavGroupOpen,
                                        })}
                                        onClick={() => handleSetOpenNavGroup(index)}
                                    >
                                        <span className={styles.label}>{item.title}</span>

                                        <ArrowIcon className={styles.arrowIcon} />
                                    </div>
                                    {isNavGroupOpen && (
                                        <div className={styles.dropdown}>
                                            {item.items.map((link) => (
                                                <Link
                                                    key={link.title}
                                                    to={link.url}
                                                    onClick={() => {
                                                        setIsMenuOpen(false);
                                                        handleSetOpenNavGroup(null);
                                                    }}
                                                    className={cn(styles.navItem, {
                                                        [styles.activeRouteNavItem]: isCurrentPath(link.url),
                                                    })}
                                                >
                                                    <span className={styles.label}>{link.title}</span>
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                </Fragment>
                            );
                        }

                        return (
                            <Link
                                key={item.title}
                                to={item.url}
                                className={cn(styles.navItem, {
                                    [styles.activeRouteNavItem]: isCurrentPath(item.url),
                                })}
                                onClick={() => setIsMenuOpen(false)}
                            >
                                <span className={styles.label}>{item.title}</span>
                            </Link>
                        );
                    })}
                    {shouldShowRoundLink && (
                        <Link className={styles.navItem} to="/campaigns/">
                            Round Dashboard
                        </Link>
                    )}
                </div>
            </div>
        </>
    );
};

export default NavMenu;
