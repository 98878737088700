import { StarButtonRenderProps } from 'ui/Rating/Rating';
import styles from './RatingStarButton.module.css';
import cn from 'classnames';
import { ReactComponent as StarLinearIcon } from 'assets/StarLinear.svg';
import { ReactComponent as StarIcon } from 'assets/Star.svg';

const RatingStarButton = ({ rating, isSelected, isDisabled, onChange }: StarButtonRenderProps) => {
    return (
        <button
            key={rating}
            className={cn(styles.star, { [styles.selected]: isSelected, [styles.disabled]: isDisabled })}
            onClick={() => {
                if (!isDisabled && typeof onChange === 'function') {
                    onChange(rating);
                }
            }}
        >
            {isSelected ? <StarIcon /> : <StarLinearIcon />}
        </button>
    );
};

export default RatingStarButton;
