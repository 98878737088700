import { ChangeEvent, CSSProperties, useRef } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { ReactComponent as CloseCircle } from 'assets/icons/CloseCircle.svg';
import styles from './SearchInput.module.css';
import cn from 'classnames';

export type SearchInputProps = {
    value: string | undefined;
    onChange: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: ChangeEvent<HTMLDivElement>) => void;
    className?: string;
    style?: CSSProperties;
    placeholder?: string;
    debounceTimeout?: number;
    inputRef?: React.RefObject<HTMLInputElement>;
    autoFocus?: boolean;
};

const SearchInput = ({
    value = '',
    onChange,
    className,
    style,
    placeholder = 'Search',
    debounceTimeout = 700,
    inputRef,
    autoFocus,
    onBlur,
}: SearchInputProps) => {
    const ref = useRef<HTMLInputElement>(inputRef?.current || null);
    const isClearable = ref?.current?.value.length;

    return (
        <div
            style={style}
            className={cn(styles.container, className)}
            onClick={() => {
                ref.current?.focus();
            }}
            onBlur={onBlur}
        >
            <SearchIcon className={styles.icon} />
            <DebounceInput
                className={cn(styles.input)}
                inputRef={ref}
                value={value}
                onChange={(e) => {
                    onChange(e.target.value, e);
                }}
                placeholder={placeholder}
                debounceTimeout={debounceTimeout}
                autoFocus={autoFocus}
            />
            <button
                className={cn(styles.clearButton, {
                    [styles.clearable]: isClearable,
                })}
                onMouseDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (!isClearable) {
                        return;
                    }

                    onChange('');
                }}
            >
                <CloseCircle />
            </button>
        </div>
    );
};

export default SearchInput;
